import ReactGA from 'react-ga';
import { Outlet } from 'react-router-dom';
import { AuthProvider } from './useAuth';

import RouteChangeTracker from './RouteChangeTracker';
import Header from './components/Header';
import MainHeader from './components/MainHeader';
import SideNav from './components/SideNav';
import Footer from './components/Footer';

const trackingId = 'UA-217314606-1';
ReactGA.initialize(trackingId);

function App() {
  return (
    <div className="App">
        <AuthProvider >
          <RouteChangeTracker />
          <Header />
          <MainHeader />
          <SideNav />

          <Outlet />

          <Footer />
        </AuthProvider >
    </div>
  );
}

export default App;
