import React from 'react';
import { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { authContext } from '../useAuth'

export default function Admin(props) {
  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const [account, setAccount] = useState({});
  const [leaderboard, setLeaderboard] = useState();

  useEffect(() => {
    if (auth.authed) {
      auth.account().then(account => {
        setAccount(account);
      });
    }

    auth.listLeaderboard().then(results => {
      console.log(results)
      setLeaderboard(results);
    });

    auth.addLeaderboardRecord();


    auth.recentRestaurant().then(results => {
      console.log("RECENT")
      console.log(results)
    });


  }, []);


  useEffect(() => {
    auth.listRestaurant().then(data => {
      console.log(data);
      for (var i = 0; i < data.length; i++) {
        //console.log(i + ' ' + data[i].name);
        if (data[i].rating === 'out_of_the_way' || 
            data[i].rating === 'no_way' || 
            data[i].rating === 'no_rating' || 
            data[i].rating === 'on_my_way') {
        }  else {
          console.log("NO MATCH")
          console.log(i + ' ' + data[i].name);
          console.log(i + ' ' + data[i].restaurant_id);
          console.log(i + ' ' + data[i].rating);
        }
 
      }
    });
  }, []);


  return (
    <div>
      <h1>ADMIN</h1>
        <p>AUTHENTICATED={auth.authed ? 'yes' : 'no' }</p>
        <p>ADMIN={auth.admin ? 'yes' : 'no' }</p>
        <p>{JSON.stringify(account)}</p>

        <a href="http://yumsights.com:7351" target="_blank" className="App-link">Nakama Console</a>
        <br />

        <br />
      <h1>LEADERBOARD</h1>
      {JSON.stringify(leaderboard)}
    </div>
  )
}

