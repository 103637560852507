import React from 'react';
import { Link } from 'react-router-dom';

export default function QuickMenu(props) {
  return (
                         <div class="row">
                            <br />
                            <br />
                            <div class="col-lg-12 col-md-12 col-sm-4 col-xs-4 align-self-center">
                                    <Link class="btn-4 btn-defaults text-center" to="/restaurant">
                                        <i class="fa fa-home fa-2x"></i>{" "}Home
                                    </Link>
                                    <span> </span>
                                    <Link class="btn-4 btn-defaults text-center" to="/addrestaurant">
                                        <i class="fa fa-sliders fa-2x"></i>{" "}Manage
                                    </Link>
                                    <span> </span>
                                    <Link class="btn-4 btn-defaults text-center" to="/map">
                                        <i class="fa fa-map fa-2x"></i>{" "}Map
                                    </Link>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-4 col-xs-4 align-self-center">
                                    <Link class="btn-4 btn-defaults text-center" to="/gallery">
                                        <i class="fa fa-picture-o fa-2x"></i>{" "}Photo
                                    </Link>
                                    <span> </span>
                                    <Link class="btn-4 btn-defaults text-center" to="/about">
                                        <i class="fa fa-book fa-2x"></i>{" "}About
                                    </Link>
                                    <span> </span>
                                    <Link class="btn-4 btn-defaults text-center" to="/quest">
                                        <i class="fa fa-users fa-2x"></i>{" "}Quest
                                    </Link>
                                    <span> </span>
                           </div>
                            <div class="col-lg-12 col-md-12 col-sm-4 col-xs-4 align-self-center">
                                    <Link class="btn-4 btn-defaults text-center" to="/about">
                                        <i class="fa fa-users fa-2x"></i>{" "}Friends
                                    </Link>
                                    <span> </span>
                                    <Link class="btn-4 btn-defaults text-center" to="/about">
                                        <i class="fa fa-share-alt fa-2x"></i>{" "}Share
                                    </Link>
                                    <span> </span>
                                    <Link class="btn-4 btn-defaults text-center" to="/about">
                                        <i class="fa fa-bar-chart fa-2x"></i>{" "}Stats
                                    </Link>
                                    <span> </span>
                           </div>
                            <div class="col-lg-12 col-md-12 col-sm-4 col-xs-4 align-self-center">
                                    <Link class="btn-4 btn-defaults text-center" to="/admin">
                                        <i class="fa fa-exclamation-triangle fa-2x"></i>{" "}TBD
                                    </Link>
                                    <span> </span>
                                    <Link class="btn-4 btn-defaults text-center" to="/about">
                                        <i class="fa fa-exclamation-triangle fa-2x"></i>{" "}TBD
                                    </Link>
                                    <span> </span>
                                    <Link class="btn-4 btn-defaults text-center" to="/about">
                                        <i class="fa fa-exclamation-triangle fa-2x"></i>{" "}TBD
                                    </Link>
                                    <span> </span>
                           </div>
                           <br />
                           <br />
                        </div>

  )
}

