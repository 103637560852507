import React from 'react';
import { useState, useEffect, useContext } from "react"
import { authContext } from '../useAuth';

export default function Stats(props) {
  const auth = useContext(authContext);

  const [dataList, setDataList] = useState([]);
  const [photoList, setPhotoList] = useState([{}]);
  const [sigdishList, setSigDishList] = useState([{}]);
  const [cuisineHist, setCuisineHist] = useState([]);
  const [restaurantHist, setRestaurantHist] = useState([]);
  const [dishHist, setDishHist] = useState([]);

  useEffect(() => {
     auth.listRestaurant().then(data => {
       console.log(data);
       console.log(data.length);
       setDataList(data);
       //generate_stats(data);
     });
  }, []);

  useEffect(() => {
     auth.listPhoto().then(results => {
          console.log("LIST results");
          console.log(results);
          setPhotoList(results);
     });
  }, []);

  useEffect(() => {
     auth.listSigDish().then(results => {
          console.log("SIGDISH results");
          console.log(results);
          generate_dish_stats(results)
     });
  }, []);

  function generate_stats(dataList) {
    if (dataList) {
      // Cuisine Histogram
      var hist = {};

      for (var i=0; i<dataList.length; i++) {
        //console.log(dataList[i].name);
        if (dataList[i].hasOwnProperty('info')) {
          if (hist.hasOwnProperty(dataList[i].info.cuisine)) {
            hist[dataList[i].info.cuisine] = hist[dataList[i].info.cuisine] + 1;
          } else {
            hist[dataList[i].info.cuisine] = 1;
          }
        }
      }
      setCuisineHist(hist);

      // Restaurant Histogram
      var hist2 = {};

      for (var i=0; i<dataList.length; i++) {
        //console.log(dataList[i].name);
          if (hist2.hasOwnProperty(dataList[i].rating)) {
            hist2[dataList[i].rating] = hist2[dataList[i].rating] + 1;
          } else {
            hist2[dataList[i].rating] = 1;
          }
      }
      setRestaurantHist(hist2);
    }
  }

  function generate_dish_stats(sigdishList) {
    if (sigdishList) {
      console.log(sigdishList);
      // SigDish Histogram
      var hist3 = {};

      for (var i=0; i<sigdishList.length; i++) {
        console.log(sigdishList[i].name);
          if (hist3.hasOwnProperty(sigdishList[i].rating)) {
            hist3[sigdishList[i].rating] = hist3[sigdishList[i].rating] + 1;
          } else {
            hist3[sigdishList[i].rating] = 1;
          }
      }
      setDishHist(hist3);
      setSigDishList(sigdishList);
    }
  }

  return (

<div class="counters-1">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="counter-box-1">
                    <i class="flaticon-symbol-1"></i>
                    <h1 class="counter">{dataList.length}</h1>
                    <h5>My Restaurants</h5>
                </div>
            </div>
            <div class="col">
                <div class="counter-box-1">
                    <i class="flaticon-tag"></i>
                    <h1 class="counter">{sigdishList.length}</h1>
                    <h5>Dishes</h5>
                </div>
            </div>
            <div class="col">
                <div class="counter-box-1">
                    <i class="flaticon-people-1"></i>
                    <h1 class="counter">{photoList.length}</h1>
                    <h5>Photos</h5>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

