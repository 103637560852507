import React from 'react';
import { Link } from 'react-router-dom';

import RestaurantRating from './RestaurantRating';

export default function RestaurantCard(props) {
  let data = props.data;

  function Card() {
    if (props.type === "list") {
      return listCard_small();
    } else {
      return gridCard_small();
    }
  }

  function listCard() {
    return (
                <div class="property row g-0 fp2 clearfix wow fadeInUp delay-03s">
                    <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="property-img">
                            <div class="property-tag button alt featured">{data.rating}</div>
                            <div class="property-tag button sale">Not Rated</div>
                            <img src="img/properties/properties-list-1.png" alt="fp-list" class="img-responsive hp-1" />
                            <div class="property-overlay">
                                <a href="properties-details.html" class="overlay-link">
                                    <i class="fa fa-link"></i>
                                </a>
                                <a class="overlay-link property-video" title="Lexus GS F">
                                    <i class="fa fa-video-camera"></i>
                                </a>
                                <div class="property-magnify-gallery">
                                    <a href="img/properties/properties-1.png" class="overlay-link">
                                        <i class="fa fa-expand"></i>
                                    </a>
                                    <a href="img/properties/properties-2.png" class="hidden"></a>
                                    <a href="img/properties/properties-3.png" class="hidden"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-12 property-content">
                        <div class="info">
                            <h1 class="title">
                                <a href="properties-details.html">{data.name}</a>
                            </h1>
                            <h3 class="property-address">
                                <a href="properties-details.html">
                                    <i class="fa fa-map-marker"></i>{data.info.address}
                                </a>
                            </h3>
                            <ul class="facilities-list clearfix">
                                <li>
                                    <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                    <span>{data.info.cuisine}</span>
                                </li>
                                        <li>
                                            <i class="flaticon-bed"></i>
                                            <span>3 Signature Dishes</span>
                                        </li>
                                        <li>
                                            <i class="flaticon-monitor"></i>
                                            <span>5 photos </span>
                                        </li>
                            </ul>
                        </div>
                        <div class="property-footer">
                            <span class="left">
                            </span>
                            <span class="right">
                            </span>
                        </div>
                    </div>
                </div>
    )
  }

  function gridCard() {
    return (
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 wow fadeInUp delay-03s">
                        <div class="property fp2">
                            <div class="property-img">
                                <div class="property-tag button alt featured">{data.rating}</div>
                                <div class="property-tag button sale">Not Rated</div>
                                <img src="img/properties/properties-1.png" alt="fp" class="img-fluid" />
                                <div class="property-overlay">
                                    <a href="properties-details.html" class="overlay-link">
                                        <i class="fa fa-link"></i>
                                    </a>
                                    <a class="overlay-link property-video" title="Lexus GS F">
                                        <i class="fa fa-video-camera"></i>
                                    </a>
                                    <div class="property-magnify-gallery">
                                        <a href="img/properties/properties-1.png" class="overlay-link">
                                            <i class="fa fa-expand"></i>
                                        </a>

                                        <a href="img/properties/properties-2.png" class="hidden"></a>
                                        <a href="img/properties/properties-3.png" class="hidden"></a>
                                    </div>
                                </div>
                            </div>
                            <div class="property-content">
                                <div class="info">
                                    <h1 class="title">
                                        <a href="properties-details.html">{data.name}</a>
                                    </h1>
                                    <h3 class="property-address">
                                        <a href="properties-details.html">
                                            <i class="fa fa-map-marker"></i>{data.info.address}
                                        </a>
                                    </h3>
                                    <ul class="facilities-list clearfix">
                                        <li>
                                            <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                            <span>{data.info.cuisine}</span>
                                        </li>
                                        <li>
                                            <i class="flaticon-bed"></i>
                                            <span>3 Signature Dishes</span>
                                        </li>
                                        <li>
                                            <i class="flaticon-monitor"></i>
                                            <span>5 photos </span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="property-footer">
                                <span class="left">
                                </span>
                                    <span class="right">
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>

    )
  }

  function listCard_small() {
    return (
                <div class="property row g-0 fp2 clearfix wow fadeInUp delay-03s">
                    <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="property-img">
                            <img src="img/properties/properties-list-1.png" alt="fp-list" class="img-responsive hp-1" />
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-12 property-content">
                        <div class="info">
                            <h1 class="title">
                                <Link to={"/restaurant/" + data.restaurant_id}>{data.name}</Link>
                            </h1>
                            <h3 class="property-address">
                                <Link to={"/restaurant/" + data.restaurant_id}>
                                    <i class="fa fa-map-marker"></i>{data.info.address}
                                </Link>
                            </h3>
                            <ul class="facilities-list clearfix">
                                <li>
                                    <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                    <span>{data.info.cuisine}</span>
                                </li>
                            </ul>
                            <ul class="facilities-list clearfix">
                                <li>
                                    <i class="flaticon-bed"></i>
                                    <span>{data.sigdish_id.length} Dishes</span>
                                </li>
                                <li>
                                    <i class="flaticon-monitor"></i>
                                    <span>{data.photos_id.length} photos </span>
                                </li>
                            </ul>
                            <br />
                            <ul class="facilities-list clearfix">
                               <RestaurantRating restaurant_id={data.restaurant_id} rating={data.rating} style="mini_static" />
                            </ul>
                        </div>
                    </div>
                </div>
    )
  }

  function gridCard_small() {
    return (
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 wow fadeInUp delay-03s">
                        <div class="property fp2">
                            <div class="property-content">
                                <div class="info">
                                    <h1 class="title">
                                        <Link to={"/restaurant/" + data.restaurant_id}>{data.name}</Link>
                                    </h1>
                                    <h3 class="property-address">
                                        <Link to={"/restaurant/" + data.restaurant_id}>
                                            <i class="fa fa-map-marker"></i>{data.info.address}
                                        </Link>
                                    </h3>

                                    <ul class="facilities-list clearfix">
                                        <li>
                                            <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                            <span>{data.info.cuisine}</span>
                                        </li>
                                    </ul>
                                    <ul class="facilities-list clearfix">
                                        <li>
                                            <i class="flaticon-bed"></i>
                                            <span>{data.sigdish_id.length} Dishes</span>
                                        </li>
                                        <li>
                                            <i class="flaticon-monitor"></i>
                                            <span>{data.photos_id.length} photos </span>
                                        </li>
                                    </ul>

                                </div>

                                <div class="property-footer">
                                  <span class="left">
                                    <span>
                                      <RestaurantRating restaurant_id={data.restaurant_id} rating={data.rating} style="mini_static" />
                                    </span>
                                  </span>
                                  <span class="right">
                                  </span>
                                </div>

                            </div>
                        </div>
                    </div>

    )
  }

  return Card();
}


