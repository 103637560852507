import { useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { authContext } from './useAuth';

import Banner from './components/Banner';
import BannerProxy from './components/BannerProxy';
import SearchArea from './components/SearchArea';
import FeaturedProperties from './components/FeaturedProperties';
import RecentProperties from './components/RecentProperties';
import Services from './components/Services';
import Counters from './components/Counters';
import Popular from './components/Popular';
import Agent from './components/Agent';
import Testimonial from './components/Testimonial';
import Blog from './components/Blog';
import Intro from './components/Intro';
import PropertyVideo from './components/PropertyVideo';

export default function Home(props) {
  const navigate = useNavigate();
  const auth = useContext(authContext);

/*
  if (auth.authed) { 
    return (
       <div>
          <Banner />
          <SearchArea />
          <FeaturedProperties />
          <RecentProperties />
          <Counters />
          <Services />
          <Popular />
       </div>
    )

  } else {
*/
    return (
       <div>
          <BannerProxy />
       </div>
    )
//  }
}

