import React from 'react';
import { Link } from 'react-router-dom';

export default function Forgot(props) {
  return (

<div class="login-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-section">
                    <div class="logo-2">
                        <Link to="/">
                            <img src="img/logos/logo.png" alt="logo" />
                        </Link>
                    </div>
                    <h3>Recover Your Password</h3>
                    <form action="#" method="GET">
                        <div class="form-group clearfix">
                            <input name="email" type="email" class="form-control" placeholder="Email Address" aria-label="Email Address" />
                        </div>
                        <div class="form-group mb-0">
                            <button type="submit" class="button-md button-theme btn-3 w-100">Send Me Email</button>
                        </div>
                    </form>
                    <p>Already a member? <Link to="/login" class="thembo"> Login here</Link></p>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

