import React from 'react';

export default function Services(props) {
  return (

<div class="services content-area-11 clearfix">
    <div class="container">
        <div class="main-title">
            <h1>What Are you Looking For?</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft delay-04s">
                <div class="service-info-2 df-box">
                    <div class="number">01</div>
                    <div class="icon">
                        <i class="flaticon-people-1"></i>
                    </div>
                    <div class="detail">
                        <h5>
                            <a href="services-1.html">Trusted By Thousands</a>
                        </h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. simply dummy Lorem Ipsum has been standard dummy.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp delay-04s">
                <div class="service-info-2 df-box">
                    <div class="number">02</div>
                    <div class="icon">
                        <i class="flaticon-symbol-1"></i>
                    </div>
                    <div class="detail">
                        <h5>
                            <a href="services-1.html">Financing Made Easy</a>
                        </h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. simply dummy Lorem Ipsum has been standard dummy text.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 wow fadeInRight delay-04s">
                <div class="service-info-2 df-box">
                    <div class="number">03</div>
                    <div class="icon">
                        <i class="flaticon-apartment"></i>
                    </div>
                    <div class="detail">
                        <h5>
                            <a href="services-1.html">Find Your Property </a>
                        </h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. simply dummy Lorem Ipsum has been standard dummy text.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 text-center wow fadeInUp delay-04s">
                <a class="btn-1 btn-outline-1" href="services-1.html">
                    <span>Read More</span> <i class="arrow"></i>
                </a>
            </div>
        </div>
    </div>
</div>

  )
}

