import React from 'react';

export default function Counters(props) {
  return (

<div class="counters-1">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft delay-04s">
                <div class="counter-box-1">
                    <i class="flaticon-symbol-1"></i>
                    <h1 class="counter">967</h1>
                    <h5>Listings For Rent</h5>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft delay-04s">
                <div class="counter-box-1">
                    <i class="flaticon-tag"></i>
                    <h1 class="counter">1276</h1>
                    <h5>Listings For Sale</h5>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInRight delay-04s">
                <div class="counter-box-1">
                    <i class="flaticon-people-1"></i>
                    <h1 class="counter">396</h1>
                    <h5>Brokers</h5>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInRight delay-04s">
                <div class="counter-box-1">
                    <i class="flaticon-people"></i>
                    <h1 class="counter">177</h1>
                    <h5>Agents</h5>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

