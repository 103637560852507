import React from 'react';
import { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { authContext } from '../useAuth';
import Form from 'react-bootstrap/Form'

export default function AddSigDish(props) {
  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const [dishList, setDishList] = useState([{}]);
  const [dish, setDish] = useState('other');
  const [cuisine, setCuisine] = useState('');

  useEffect(() => {
    auth.getRestaurant(props.restaurant_id).then(data => {
      setCuisine(data.info.cuisine);
      auth.listDish(data.info.cuisine).then(results => {
        //console.log("Dish results");
        //console.log(results);
        results.sort((a,b) => a.name.localeCompare(b.name));
        setDishList(results);
        //setDish(results[0].name);
      });
    });
  }, [props.restaurant_id]);

  const handleSubmit= (e) => {
    e.preventDefault();

    var data = {
      'name' : e.target.name.value,
      'dish' : dish,
      'description' : e.target.description.value
    }
    console.log(data)

    if (e.target.name.value === "") {
      window.alert("Please enter dish name")
    }

    auth.addSigDish(props.restaurant_id, data).then(data => {
      console.log('New dish_id' + data);
    });;
  }

  function handleChange(e) {
    setDish(e.target.value);
  }
  
  if (props.visible === "0") {
    return (
      <div></div>
    )
  } else {
    return (
      <form onSubmit={e => {handleSubmit(e)}}>
        <input 
          name='name' 
          class='form-control' 
          placeholder='Enter name of dish' 
          type='text'
        />
        <br />
        <input
          name='description' 
          class='form-control' 
          placeholder='Enter description' 
          type='text'
        />
        <br/>
        <Form.Select aria-label="Default select example" onChange={(e) => handleChange(e)}>
            {dishList.map((option) => (
              <option selected={option.name === dish} value={option.name}>{option.name}</option>
            ))}
        </Form.Select>
        <br/>

        <input 
          className='submitButton'
          type='submit' 
          value='Submit' 
        />
      </form>
    )
  }
}

