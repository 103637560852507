import React from 'react';
import { useContext } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { authContext } from '../useAuth';


export default function Login(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = useContext(authContext);


  const handleSubmit= (e) => {
    console.log(auth);
    e.preventDefault();

    let username = '';
    let email = e.target.email.value;
    let password = e.target.password.value;

    auth.login(username, email, password).then(() => {
      if (state && state.path !== '/login') {
        console.log(state.path)
        navigate(state.path);
      } else {
        console.log("navigate to default")
        navigate("/");
      }
    });
  }

  return (

<div class="login-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-section">
                    <div class="logo-2">
                        <Link to="/">
                            <img src="img/logos/logo.png" alt="logo" />
                        </Link>
                    </div>
                    <h3>Sign Into Your Account</h3>
                      <form onSubmit={e => {handleSubmit(e)}}>
                        <div class="form-group clearfix">
                            <input name="email" type="email" class="form-control" placeholder="Email Address" aria-label="Email Address" />
                        </div>
                        <div class="form-group clearfix">
                            <input name="password" type="password" class="form-control" placeholder="Password" aria-label="Password" />
                        </div>
                        <div class="checkbox form-group clearfix">
                            <div class="form-check float-start">
                                <input class="form-check-input" type="checkbox" id="rememberme" />
                                <label class="form-check-label" htmlFor="rememberme">
                                    Remember me
                                </label>
                            </div>
                            <Link to="/forgot" class="link-light float-end forgot-password">Forgot your password?</Link>
                        </div>
                        <div class="form-group mb-0 clearfix">
                            <button type="submit" class="button-md button-theme btn-3 w-100">Login</button>
                        </div>
                    </form>
                    <p>Don't have an account? <Link to="/signup" class="thembo"> Register here</Link></p>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

