import React from 'react';
import { useEffect, useContext } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from 'react-ga';

import { authContext } from './useAuth';

export default function RouteChangeTracker(props) {
  const { pathname } = useLocation();
  const auth = useContext(authContext);

  const production = true;

  useEffect(() => {
     if (auth.admin) {
       return;
     }

     //console.log("TRACK");
     //console.log(pathname);

     if (pathname.includes('restaurant/')) {
       let root = pathname.split('/')[1];
       let id = pathname.split('/')[2];
       let pageHit = '/' + root + '/restaurant_id'
       if (production) {
         ReactGA.pageview(pageHit);
       }

     } else {
       if (production) {
         ReactGA.set({ page : pathname });
         ReactGA.pageview(pathname);
       }
     }
  }, [pathname]);

  return (
     <div>
     </div>
  )
}

