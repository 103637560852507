import { useState, useEffect, useContext } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import { authContext } from '../useAuth';
import QRCode from 'qrcode.react';

import Camera from './Camera';
import RestaurantRating from './RestaurantRating';
import DishCard from './DishCard';
import DishRating from './DishRating';
import MapContainer from './MapContainer';
import AddSigDish from './AddSigDish';


export default function RestaurantDetail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const [refresh, setRefresh] = useState(0);
  const [myData, setMyData] = useState({});
  const [dishForm, setDishForm] = useState("0");

  const [ratingForm, setRatingForm] = useState("mini_static");

  const [notes, setNotes] = useState('');
  const [notechanged, setNoteChanged] = useState(false);

  const [tags, setTags] = useState('');
  const [tagchanged, setTagChanged] = useState(false);

  const [dataList, setDataList] = useState([]);
  const [nameFilter, setNameFilter] = useState('');


  useEffect(() => {
    auth.getRestaurant(params.restaurant_id).then(data => {
      if (!data) {
        return;
      }

      setMyData(data);
      setNotes(data.description);
      setTags(data.tags);

      if (!data.info) {
        return;
      }
    });

    auth.listRestaurant().then(data => {
      console.log(data);
      data.sort((a,b) => a.name.localeCompare(b.name));
      setDataList(data);
    });

    //window.scrollTo({
    //  top: 0,
    //  left: 0,
    //  behavior: 'smooth'
    //});
  }, [auth, params.restaurant_id, refresh]);

  function toggleRatingForm() {
     if (ratingForm === "full") {
       setRatingForm("mini_static")
     } else {
       setRatingForm("full")
     }
     toggleRefresh()
  }

  function toggleRefresh() {
     if (refresh === 0) {
       setRefresh(1)
     } else {
       setRefresh(0)
     }
  }

  function toggleDishForm() {
     if (dishForm === "0") {
       setDishForm("1")
     } else {
       setDishForm("0")
     }
  }

  function handleNotes(e) {
    setNotes(e.target.value);
    setNoteChanged(true);
  }

  function handleNoteInit() {
    setNoteChanged(false);
  }

  function handleNoteDone(restaurant_id) {
    if (notechanged === true) {
      if (window.confirm('Save changes to notes?')) {
        console.log(notes);
        var querydata = {
          "description" : notes
        }
        console.log('rest_id=' + restaurant_id);
        auth.updateRestaurant(restaurant_id, querydata);
      } else {
      }
    }
  }

  function handleTags(e) {
    setTags(e.target.value);
    setTagChanged(true);
  }

  function handleTagInit() {
    setTagChanged(false);
  }

  function handleTagDone(restaurant_id) {
    if (tagchanged === true) {
      if (window.confirm('Save changes to tags?')) {
        console.log(tags);
        var querydata = {
          "tags" : tags
        }
        console.log('rest_id=' + restaurant_id);
        auth.updateRestaurant(restaurant_id, querydata);
      } else {
      }
    }
  }

  function handleDelete() {
    if (window.confirm('Remove restaurant: ' + myData.info.name)) {
      console.log(myData.restaurant_id)
      auth.delRestaurant(myData.restaurant_id)
      navigate("/")
    }
  }

  function handleSearch(e) {
    e.preventDefault();
    console.log(e.target.value)
    setNameFilter(e.target.value.toLowerCase())
  }

  function customFilter(data) {
      let name = data.info.name.toLowerCase()
      if (name.includes(nameFilter)) {
        return true;
      } else {
        return false;
      }
  }

  function restaurant_dish(data) {
    if (!data.hasOwnProperty('sigdish')) {
      return (null);
    }
    var dish = data.sigdish
    var num_dish = data.sigdish.length

    return(
      <div>
        {dish.map(dataItem => (
          <div key={dataItem.id} class="dish-box properties-description sidebar-widget mb-30 row">
           <DishCard restaurant_id={data.restaurant_id} data={dataItem}/>
          </div>
        ))}

        <div class="add-box mb-30">
           <h4>
             <div class="row">
             <div class="col">
               <button type="submit" class="btn" onClick={() => toggleDishForm()}>
                <i class="fa fa-plus"></i>{" "}Add a Dish
               </button>
               <AddSigDish restaurant_id={data.restaurant_id} visible={dishForm}/>
             </div>
             </div>
           </h4>
        </div>

        <div class="add-box mb-30">
           <h4>
             <div class="row">
             <div class="col">
               <button type="submit" class="btn" onClick={() => toggleRefresh()}>
                <i class="fa fa-refresh"></i>{" "}Refresh Page
               </button>
             </div>
             </div>
           </h4>
        </div>
      </div>
    )
  }

  function restaurant_photos(data) {
    if (!data.hasOwnProperty('photos')) {
      return (null);
    }
    var photos = data.photos

    return(
      <div className="row">
        <div className="col-md">
          <div className="row no-gutters">
            {photos.map(dataItem => (
              <div key={dataItem.id} class="col-sm-4">
               <img src={dataItem.url} alt="photo" class={dataItem.orientation + " img-fluid"}/>
               <br />
               <br />
              </div>
            ))}
          <br />
          </div>
        </div>
      </div>
    )
  }

  function restaurant_info(data) {
    if (!data.hasOwnProperty('info')) {
      return (null);
    }
    var info   = data.info

    return (
      <div class="heading-properties clearfix sidebar-widget">
          <div class="pull-right">
              <h5>
                {myData.info.cuisine}
              </h5>     
          </div>   
          <div class="pull-left">
             <RestaurantRating restaurant_id={myData.restaurant_id} rating={myData.rating} style={ratingForm}/>
             <br />
             <h3>{myData.info.name}</h3>
             <p>
               <i class="fa fa-map-marker"></i>{myData.info.address}
             </p>
             <p>
               <a href={"https://www.google.com/maps/search/?api=1&query=>&query_place_id=" + myData.info.place_id} target="_blank" rel="noreferrer" class="link-primary">Directions</a>
             </p>
          </div>

           <div class="row">
           <div class="col-10"></div>
           <div class="col-auto">
           <button type="submit" class="btn" onClick={() => toggleRatingForm()}>
             <i class="fa fa-pencil"></i>
           </button>
           <button type="submit" class="btn" onClick={() => handleDelete()}>
             <i class="fa fa-trash"></i>
           </button>
           </div>
           </div>

      </div>    
    )
  }

  function restaurant_location(data) {
    if (!data.hasOwnProperty('info')) {
      return (null);
    }
    var info   = data.info

    return (
      <MapContainer location={data.info.location}/>
    )
  }

  function restaurant_notes(data) {
    return (
      <div class="heading-properties clearfix sidebar-widget">
        <h3>Notes</h3>
        <div class="form-outline">
           <textarea class="form-control" rows="4" placeholder="Enter personal notes here" onChange={(e) => handleNotes(e)} onFocus={() => handleNoteInit()} onBlur={() => handleNoteDone(params.restaurant_id)} defaultValue={data.description}>
           </textarea>
        </div>
        <br />
        <h3>Tags</h3>
        <div class="form-outline">
           <textarea class="form-control" rows="1" placeholder="Enter tags" onChange={(e) => handleTags(e)} onFocus={() => handleTagInit()} onBlur={() => handleTagDone(params.restaurant_id)} defaultValue={data.tags}>
           </textarea>
        </div>
      </div>    
    )
  }

  function restaurant_camera(data) {
    return (
      <div class="clearfix sidebar-widget">
        <Camera restaurant_id={data.restaurant_id}/>
      </div>    
    )
  }

  function restaurant_share(data) {
    let url = "/restaurant/" + data.restaurant_id; 
    let link_url = "http://yumsights.com:3000" + url;

    return (
      <Link to={url}>
        <QRCode value={link_url}/>
        <p>{link_url}</p>
      </Link>
    )
  }

  return (

<div>

<div class="sub-banner">
    <div class="overlay">
        <div class="container">
        </div>
    </div>
</div>

<div class="properties-details-page content-area">
    <div class="container">
        <div class="row mb-20">
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                {restaurant_info(myData)}
                <br />
                {restaurant_camera(myData)}
                <br />
                {restaurant_dish(myData)}
                <br />
                {restaurant_photos(myData)}
                <br />
                {restaurant_notes(myData)}
                <br />

                <div class="location sidebar-widget">
                    <div class="map">
                        <div class="main-title-2">
                            <h1><span>Map</span></h1>
                        </div>
                        {restaurant_location(myData)}
                    </div>
                </div>

                <div class="location sidebar-widget">
                  <div class="main-title-2">
                    <h1><span>Share</span></h1>
                  </div>
                  {restaurant_share(myData)}
                </div>

                <div class="sidebar-widget">
                    <div class="advanced-search d-lg-none">
                        <div class="main-title-2">
                            <h1>Advanced Search</h1>
                        </div>


                        <h4>Search by Name</h4>
                        <div class="form">
                          <input type="text" name="your-name" class="form-control" placeholder="Search.." onChange={(e) => handleSearch(e)}/>
                        </div>
                        <br />
                        <h4>Results:</h4>
                        Selected entries: {dataList.filter(customFilter).length} out of {dataList.length}
                        <br />
                        <br />
                        <div class="row">
                          {dataList
                            .filter(customFilter)
                            .map(dataItem => (
                              <li>
                                 <Link to={"/restaurant/" + dataItem.restaurant_id} class="sign-in">{dataItem.name}</Link>
                              </li>
                          ))}
                        </div>
                      </div>
                </div>

            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div class="sidebar left">
                    <div class="sidebar-widget advanced-search as2">
                        <div class="main-title-2">
                            <h1>Advanced Search</h1>
                        </div>

                        <h4>Search by Name</h4>
                        <div class="form">
                          <input type="text" name="your-name" class="form-control" placeholder="Search.." onChange={(e) => handleSearch(e)}/>
                        </div>
                        <br />
                        <h4>Results:</h4>
                        Selected entries: {dataList.filter(customFilter).length} out of {dataList.length}
                        <br />
                        <br />
                        <div class="row">
                          {dataList
                            .filter(customFilter)
                            .map(dataItem => (
                              <li>
                                 <Link to={"/restaurant/" + dataItem.restaurant_id} class="sign-in">{dataItem.name}</Link>
                              </li>
                          ))}
                        </div>
                    </div>

                    <div class="social-media sidebar-widget clearfix">
                        <div class="main-title-2">
                            <h1><span>Social</span> Media</h1>
                        </div>
                        <ul class="social-list">
                            <li><a href="#" class="facebook-bg"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="#" class="twitter-bg"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="#" class="linkedin-bg"><i class="fa fa-linkedin"></i></a></li>
                            <li><a href="#" class="google-bg"><i class="fa fa-google-plus"></i></a></li>
                            <li><a href="#" class="rss-bg"><i class="fa fa-rss"></i></a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="comon-slick recently-properties">
        <div class="container">
            <div class="main-title-2">
                <h1><span>Related Restaurants</span></h1>
                {params.restaurant_id}
            </div>
        </div>
    </div>
</div>

</div>

  )
}

