import { useState, useEffect, useContext } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import { authContext } from '../useAuth';
import QRCode from 'qrcode.react';

import Leaderboard from './Leaderboard';
import LeaderboardMap from './LeaderboardMap';
import RestaurantCard from './RestaurantCard';
import Comments from './Comments';

export default function Quest(props) {
  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const [dataList, setDataList] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    auth.listRestaurant().then(data => {
      console.log(data);
      data.sort((a,b) => a.name.localeCompare(b.name));
      setDataList(data);
    });
  }, []);

  function handleCallback(restaurant_id) {
    console.log("CALLBACK")
    setSelectedItem(restaurant_id);
  }

  function mainFilter(data,k) {
      if (k < 10) {
        return true;
      } else {
        return false;
      }
  }


  function selectedFilter(data) {
      if (data.restaurant_id == selectedItem) {
        return true;
      } else {
        return false;
      }
  }

  function customFilter(data) {
      let name = data.info.name.toLowerCase()
      if (name.includes(nameFilter)) {
        return true;
      } else {
        return false;
      }
  }

  function handleSelect(e, restaurant_id) {
    e.preventDefault();
    setSelectedItem(restaurant_id);
  }

  function handleSearch(e) {
    e.preventDefault();
    console.log(e.target.value)
    setNameFilter(e.target.value.toLowerCase())
  }

  function quest_map() {
    var location = "{\"lat\":37.3197333,\"lng\":-121.9722722}";
    return (
      <LeaderboardMap location={location}/>
    )
  }

  return (
<div>

<div class="properties-details-page content-area">
    <div class="container">
        <div class="row mb-20">
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div class="heading-properties clearfix sidebar-widget">
                        <div class="main-title-2">
                            <h1><span>Quest: Finding the best burrito in Bay area</span></h1>
                            <br />
                            <div class="row">
                            <div class="col">
                              <li>Cuisine:  Mexican</li>
                              <li>Dish:     Burrito</li>
                              <li>Locale:   within 20 miles of 95124</li>
                            </div>

                            <div class="col">
                              <li>Restaurants:       {dataList.filter(customFilter).length}</li>
                              <li>Users:     93</li>
                              <li>Ratings:   124</li>
                            </div>
                            </div>
                        </div>
                </div>

                <div class="heading-properties clearfix sidebar-widget">
                        <div class="main-title-2">
                            <h1><span>Main Leaderboard</span></h1>
                        </div>
                        <Leaderboard type='main' data={dataList.filter(mainFilter)} callback={handleCallback}/>
                </div>
                       
                <div class="heading-properties clearfix sidebar-widget">
                        <div class="main-title-2">
                            <h1><span>My Rankings</span></h1>
                        </div>
                        <Leaderboard data={dataList.filter(customFilter)} callback={handleCallback}/>
                </div>

                <div class="heading-properties clearfix sidebar-widget">
                        <div class="main-title-2">
                            <h1><span>Comments</span></h1>
                            <Comments />
                        </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div class="sidebar left">
                    <div class="sidebar-widget advanced-search as2">
                      {quest_map()}
                    </div>
                    <div class="sidebar-widget advanced-search as2">
                          {dataList
                            .filter(selectedFilter)
                            .map(dataItem => (
                              <RestaurantCard type='list' data={dataItem}/>
                          ))}
                    </div>
                    <div class="sidebar-widget advanced-search as2">
                        <div class="main-title-2">
                            <h1>Advanced Search</h1>
                        </div>

                        <h4>Search by Name</h4>
                        <div class="form">
                          <input type="text" name="your-name" class="form-control" placeholder="Search.." onChange={(e) => handleSearch(e)}/>
                        </div>
                        <br />
                        <h4>Results:</h4>
                        Selected entries: {dataList.filter(customFilter).length} out of {dataList.length}
                        <br />
                        <br />
                        <div class="row">
                          {dataList
                            .filter(customFilter)
                            .map(dataItem => (
                              <li>
                                 <a onClick={(e)=> handleSelect(e, dataItem.restaurant_id)}>{dataItem.name}</a>
                              </li>
                          ))}
                        </div>
                    </div>

                    <div class="social-media sidebar-widget clearfix">
                        <div class="main-title-2">
                            <h1><span>Social</span> Media</h1>
                        </div>
                        <ul class="social-list">
                            <li><a href="#" class="facebook-bg"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="#" class="twitter-bg"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="#" class="linkedin-bg"><i class="fa fa-linkedin"></i></a></li>
                            <li><a href="#" class="google-bg"><i class="fa fa-google-plus"></i></a></li>
                            <li><a href="#" class="rss-bg"><i class="fa fa-rss"></i></a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

</div>

  )
}

