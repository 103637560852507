import React from 'react';
import { useContext } from "react"
import { authContext } from '../useAuth';
import { Link } from 'react-router-dom';

import QuickMenu from './QuickMenu';

export default function MainHeader(props) {
  const auth = useContext(authContext);

  if (!auth.authed) {
    return (
      <div></div>
    )
  } else {
    return (

<header class="main-header  header-shrink ">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <QuickMenu />
        </nav>
    </div>
</header>
    )
  }
}

