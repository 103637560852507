import React from 'react';

export default function FeaturedProperties(props) {
  return (

<div class="content-area featured-properties">
    <div class="container">
        <div class="main-title">
            <h1>Featured Properties</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft delay-04s">
                <div class="row g-0 property fp2">
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                        <div class="property-img">
                            <div class="property-tag button alt featured">Featured</div>
                            <div class="property-price">$150,000</div>
                            <img src="img/properties/properties-14.png" alt="fp-list" class="img-responsive hp-2" />
                            <div class="property-overlay">
                                <a href="properties-details.html" class="overlay-link">
                                    <i class="fa fa-link"></i>
                                </a>
                                <a class="overlay-link property-video" title="Lexus GS F">
                                    <i class="fa fa-video-camera"></i>
                                </a>
                                <div class="property-magnify-gallery">
                                    <a href="img/properties/properties-14.png" class="overlay-link">
                                        <i class="fa fa-expand"></i>
                                    </a>
                                    <a href="img/properties/properties-13.png" class="hidden"></a>
                                    <a href="img/properties/properties-15.png" class="hidden"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 property-content">
                        <div class="info">
                            <h1 class="title">
                                <a href="properties-details.html">Beautful Single Home</a>
                            </h1>
                            <h3 class="property-address">
                                <a href="properties-details.html">
                                    <i class="fa fa-map-marker"></i>123 Kathal St. Tampa City,
                                </a>
                            </h3>
                            <ul class="facilities-list fl-2 clearfix">
                                <li>
                                    <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                    <span>4800 sq ft</span>
                                </li>
                                <li>
                                    <i class="flaticon-bed"></i>
                                    <span>3 Bedrooms</span>
                                </li>
                                <li>
                                    <i class="flaticon-holidays"></i>
                                    <span>2 Bathrooms</span>
                                </li>
                                <li>
                                    <i class="flaticon-vehicle"></i>
                                    <span>1 Garage </span>
                                </li>
                            </ul>
                        </div>
                        <div class="property-footer">
                            <span class="left">
                                <a href="#"><i class="fa fa-user"></i>Jhon Doe</a>
                            </span>
                            <span class="right">
                                    <i class="fa fa-calendar"></i>5 Days ago
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 wow fadeInRight delay-04s">
                <div class="row g-0 property fp2">
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                        <div class="property-img">
                            <div class="property-tag button alt featured">Featured</div>
                            <div class="property-price">$150,000</div>
                            <img src="img/properties/properties-15.png" alt="fp-list" class="img-responsive hp-2" />
                            <div class="property-overlay">
                                <a href="properties-details.html" class="overlay-link">
                                    <i class="fa fa-link"></i>
                                </a>
                                <a class="overlay-link property-video" title="Lexus GS F">
                                    <i class="fa fa-video-camera"></i>
                                </a>
                                <div class="property-magnify-gallery">
                                    <a href="img/properties/properties-15.png" class="overlay-link">
                                        <i class="fa fa-expand"></i>
                                    </a>
                                    <a href="img/properties/properties-13.png" class="hidden"></a>
                                    <a href="img/properties/properties-14.png" class="hidden"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 property-content">
                        <div class="info">
                            <h1 class="title">
                                <a href="properties-details.html">Modern Family Home</a>
                            </h1>
                            <h3 class="property-address">
                                <a href="properties-details.html">
                                    <i class="fa fa-map-marker"></i>123 Kathal St. Tampa City,
                                </a>
                            </h3>
                            <ul class="facilities-list fl-2 clearfix">
                                <li>
                                    <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                    <span>4800 sq ft</span>
                                </li>
                                <li>
                                    <i class="flaticon-bed"></i>
                                    <span>3 Bedrooms</span>
                                </li>
                                <li>
                                    <i class="flaticon-holidays"></i>
                                    <span>2 Bathrooms</span>
                                </li>
                                <li>
                                    <i class="flaticon-vehicle"></i>
                                    <span>1 Garage </span>
                                </li>
                            </ul>
                        </div>
                        <div class="property-footer">
                            <span class="left">
                                <a href="#"><i class="fa fa-user"></i>Jhon Doe</a>
                            </span>
                            <span class="right">
                                <i class="fa fa-calendar"></i>5 Days ago
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft delay-04s">
                <div class="row g-0 property fp2">
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                        <div class="property-img">
                            <div class="property-tag button alt featured">Featured</div>
                            <div class="property-price">$150,000</div>
                            <img src="img/properties/properties-13.png" alt="fp-list" class="img-responsive hp-2" />
                            <div class="property-overlay">
                                <a href="properties-details.html" class="overlay-link">
                                    <i class="fa fa-link"></i>
                                </a>
                                <a class="overlay-link property-video" title="Lexus GS F">
                                    <i class="fa fa-video-camera"></i>
                                </a>
                                <div class="property-magnify-gallery">
                                    <a href="img/properties/properties-13.png" class="overlay-link">
                                        <i class="fa fa-expand"></i>
                                    </a>
                                    <a href="img/properties/properties-14.png" class="hidden"></a>
                                    <a href="img/properties/properties-15.png" class="hidden"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 property-content ">
                        <div class="info">
                            <h1 class="title">
                                <a href="properties-details.html">Sweet Family Home</a>
                            </h1>
                            <h3 class="property-address">
                                <a href="properties-details.html">
                                    <i class="fa fa-map-marker"></i>123 Kathal St. Tampa City,
                                </a>
                            </h3>
                            <ul class="facilities-list fl-2 clearfix">
                                <li>
                                    <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                    <span>4800 sq ft</span>
                                </li>
                                <li>
                                    <i class="flaticon-bed"></i>
                                    <span>3 Bedrooms</span>
                                </li>
                                <li>
                                    <i class="flaticon-holidays"></i>
                                    <span>2 Bathrooms</span>
                                </li>
                                <li>
                                    <i class="flaticon-vehicle"></i>
                                    <span>1 Garage </span>
                                </li>
                            </ul>
                        </div>
                        <div class="property-footer">
                            <span class="left">
                                <a href="#"><i class="fa fa-user"></i>Jhon Doe</a>
                            </span>
                            <span class="right">
                                    <i class="fa fa-calendar"></i>5 Days ago
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 wow fadeInRight delay-04s">
                <div class="row g-0 property fp2">
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                        <div class="property-img">
                            <div class="property-tag button alt featured">Featured</div>
                            <div class="property-price">$150,000</div>
                            <img src="img/properties/properties-16.png" alt="fp-list" class="img-responsive hp-2" />
                            <div class="property-overlay">
                                <a href="properties-details.html" class="overlay-link">
                                    <i class="fa fa-link"></i>
                                </a>
                                <a class="overlay-link property-video" title="Lexus GS F">
                                    <i class="fa fa-video-camera"></i>
                                </a>
                                <div class="property-magnify-gallery">
                                    <a href="img/properties/properties-16.png" class="overlay-link">
                                        <i class="fa fa-expand"></i>
                                    </a>
                                    <a href="img/properties/properties-14.png" class="hidden"></a>
                                    <a href="img/properties/properties-15.png" class="hidden"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 property-content">
                        <div class="info">
                            <h1 class="title">
                                <a href="properties-details.html">Masons Villas</a>
                            </h1>
                            <h3 class="property-address">
                                <a href="properties-details.html">
                                    <i class="fa fa-map-marker"></i>123 Kathal St. Tampa City,
                                </a>
                            </h3>
                            <ul class="facilities-list fl-2 clearfix">
                                <li>
                                    <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                    <span>4800 sq ft</span>
                                </li>
                                <li>
                                    <i class="flaticon-bed"></i>
                                    <span>3 Bedrooms</span>
                                </li>
                                <li>
                                    <i class="flaticon-holidays"></i>
                                    <span>2 Bathrooms</span>
                                </li>
                                <li>
                                    <i class="flaticon-vehicle"></i>
                                    <span>1 Garage </span>
                                </li>
                            </ul>
                        </div>
                        <div class="property-footer">
                            <span class="left">
                                <a href="#"><i class="fa fa-user"></i>Jhon Doe</a>
                            </span>
                            <span class="right">
                                    <i class="fa fa-calendar"></i>5 Days ago
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

