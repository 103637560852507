import React from 'react';

export default function Comments(props) {
  return (
<div class="comments-section sidebar-widget">
                    <ul class="comments">
                        <li>
                            <div class="comment">
                                <div class="comment-author">
                                    <a href="#">
                                        <img src="img/avatar/avatar-5.png" alt="avatar-5" />
                                    </a>
                                </div>
                                <div class="comment-content">
                                    <div class="comment-meta">
                                        <div class="comment-meta-author">
                                            John Antony
                                        </div>
                                        <div class="comment-meta-reply float-end">
                                            <a href="#">Reply</a>
                                        </div>
                                        <div class="comment-meta-date float-end">
                                            8:55 PM 7/4/2021
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="comment-body">
                                        <div class="comment-rating">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star-o"></i>
                                            <span>( 4 Reviews )</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate.</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="comment">
                                <div class="comment-author">
                                    <a href="#">
                                        <img src="img/avatar/avatar-5.png" alt="avatar-5" />
                                    </a>
                                </div>
                                <div class="comment-content">
                                    <div class="comment-meta">
                                        <div class="comment-meta-author">
                                            Eliane Pereira
                                        </div>
                                        <div class="comment-meta-reply float-end">
                                            <a href="#">Reply</a>
                                        </div>
                                        <div class="comment-meta-date float-end">
                                            8:45 PM 7/3/2020
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="comment-body">
                                        <div class="comment-rating">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star-o"></i>
                                            <span>( 4 Reviews )</span>
                                        </div>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <div class="comment comment-mrg-bdr-nane mb-0">
                                        <div class="comment-author">
                                            <a href="#">
                                                <img src="img/avatar/avatar-5.png" alt="avatar-5" />
                                            </a>
                                        </div>
                                        <div class="comment-content cc2">
                                            <div class="comment-meta">
                                                <div class="comment-meta-author">
                                                    Jane Doe
                                                </div>
                                                <div class="comment-meta-reply float-end">
                                                    <a href="#">Reply</a>
                                                </div>
                                                <div class="comment-meta-date float-end">
                                                    8:42 PM 3/3/2021
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="comment-body">
                                                <div class="comment-rating">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star-o"></i>
                                                    <span>( 4 Reviews )</span>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

  )
}


