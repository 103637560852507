import React from 'react';
import { useEffect, useContext } from "react"
import { useNavigate, } from "react-router-dom"
import { authContext } from '../useAuth';


export default function Logout(props) {
  const navigate = useNavigate();
  const auth = useContext(authContext);

  useEffect(() => {
    auth.logout();
    navigate("/");
  }, []);

  return (
    <div></div>
  )
}

