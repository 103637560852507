import React from 'react';

export default function RecentProperties(props) {
  return (

<div class="content-area-17 comon-slick recently-properties">
    <div class="container">
        <div class="main-title">
            <h1>Recently Properties</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
        </div>
        <div class="slick row comon-slick-inner" data-slick='{"slidesToShow": 4, "responsive":[{"breakpoint": 1024,"settings":{"slidesToShow": 2}}, {"breakpoint": 768,"settings":{"slidesToShow": 1}}]}'>
            <div class="item slide-box wow fadeInRight delay-04s">
                <div class="property-2">
                    <div class="property-inner">
                        <div class="property-overflow">
                            <div class="property-photo">
                                <img src="img/recently-properties/img-1.png" alt="rp" class="img-fluid" />
                                <div class="listing-badges">
                                    <span class="featured active">For Sale</span>
                                </div>
                                <div class="price-ratings">
                                    <div class="price">$72.000</div>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <h4 class="title">
                                <a href="properties-details.html">Big Head House</a>
                            </h4>
                            <h3 class="property-address">
                                <a href="properties-details.html">
                                    <i class="fa fa-map-marker"></i>123 Kathal St. Tampa City,
                                </a>
                            </h3>
                        </div>
                        <ul class="facilities-list clearfix">
                            <li>
                                <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                <span>720 sq ft</span>
                            </li>
                            <li>
                                <i class="flaticon-bed"></i>
                                <span>3Bed</span>
                            </li>
                            <li>
                                <i class="flaticon-holidays"></i>
                                <span>2Bath</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="item slide-box wow fadeInRight delay-04s">
                <div class="property-2">
                    <div class="property-inner">
                        <div class="property-overflow">
                            <div class="property-photo property-img">
                                <img src="img/recently-properties/img-2.png" alt="rp" class="img-fluid" />
                                <div class="listing-badges">
                                    <span class="featured red">For Rent</span>
                                </div>
                                <div class="price-ratings">
                                    <div class="price">$72.000</div>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <h4 class="title">
                                <a href="properties-details.html">Masons Villas</a>
                            </h4>
                            <h3 class="property-address">
                                <a href="properties-details.html">
                                    <i class="fa fa-map-marker"></i>123 Kathal St. Tampa City,
                                </a>
                            </h3>
                        </div>
                        <ul class="facilities-list clearfix">
                            <li>
                                <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                <span>720 sq ft</span>
                            </li>
                            <li>
                                <i class="flaticon-bed"></i>
                                <span>3Bed</span>
                            </li>
                            <li>
                                <i class="flaticon-holidays"></i>
                                <span>2Bath</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="item slide-box wow fadeInLeft delay-04s">
                <div class="property-2">
                    <div class="property-inner">
                        <div class="property-overflow">
                            <div class="property-photo">
                                <img src="img/recently-properties/img-3.png" alt="rp" class="img-fluid" />
                                <div class="listing-badges">
                                    <span class="featured active">For Sale</span>
                                </div>
                                <div class="price-ratings">
                                    <div class="price">$72.000</div>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <h4 class="title">
                                <a href="properties-details.html">Park Avenue</a>
                            </h4>
                            <h3 class="property-address">
                                <a href="properties-details.html">
                                    <i class="fa fa-map-marker"></i>123 Kathal St. Tampa City,
                                </a>
                            </h3>
                        </div>
                        <ul class="facilities-list clearfix">
                            <li>
                                <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                <span>720 sq ft</span>
                            </li>
                            <li>
                                <i class="flaticon-bed"></i>
                                <span>3Bed</span>
                            </li>
                            <li>
                                <i class="flaticon-holidays"></i>
                                <span>2Bath</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="item slide-box wow fadeInLeft delay-04s">
                <div class="property-2">
                    <div class="property-inner">
                        <div class="property-overflow">
                            <div class="property-photo property-img">
                                <img src="img/recently-properties/img-4.png" alt="rp" class="img-fluid" />
                                <div class="listing-badges">
                                    <span class="featured red">For Rent</span>
                                </div>
                                <div class="price-ratings">
                                    <div class="price">$72.000</div>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <h4 class="title">
                                <a href="properties-details.html">Park Avenue</a>
                            </h4>
                            <h3 class="property-address">
                                <a href="properties-details.html">
                                    <i class="fa fa-map-marker"></i>123 Kathal St. Tampa City,
                                </a>
                            </h3>
                        </div>
                        <ul class="facilities-list clearfix">
                            <li>
                                <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                <span>720 sq ft</span>
                            </li>
                            <li>
                                <i class="flaticon-bed"></i>
                                <span>3Bed</span>
                            </li>
                            <li>
                                <i class="flaticon-holidays"></i>
                                <span>2Bath</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="item slide-box wow fadeInLeft delay-04s">
                <div class="property-2">
                    <div class="property-inner">
                        <div class="property-overflow">
                            <div class="property-photo property-img">
                                <img src="img/recently-properties/img-3.png" alt="rp" class="img-fluid" />
                                <div class="listing-badges">
                                    <span class="featured red">For Rent</span>
                                </div>
                                <div class="price-ratings">
                                    <div class="price">$72.000</div>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <h4 class="title">
                                <a href="properties-details.html">Family Villa</a>
                            </h4>
                            <h3 class="property-address">
                                <a href="properties-details.html">
                                    <i class="fa fa-map-marker"></i>123 Kathal St. Tampa City,
                                </a>
                            </h3>
                        </div>
                        <ul class="facilities-list clearfix">
                            <li>
                                <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                                <span>720 sq ft</span>
                            </li>
                            <li>
                                <i class="flaticon-bed"></i>
                                <span>3Bed</span>
                            </li>
                            <li>
                                <i class="flaticon-holidays"></i>
                                <span>2Bath</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

