import React from 'react';
import { useContext } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { authContext } from '../useAuth';


export default function Signup(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = useContext(authContext);


  const handleSubmit= (e) => {
    e.preventDefault();
    var username = e.target.name.value;
    var email = e.target.email.value;
    var password = e.target.password.value;
    var invite = e.target.invite.value;

    if (invite !== 'yumfriend') {
      return;
    }

    auth.register(username, email, password).then(() => {
      if (state) {
        navigate(state.path);
      } else {
        navigate("/");
      }
    });
  }


  return (

<div class="login-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-section">
                    <div class="logo-2">
                        <Link to="/">
                            <img src="img/logos/logo.png" alt="logo" />
                        </Link>
                    </div>
                    <h3>Create An Account</h3>
                    <form onSubmit={e => {handleSubmit(e)}}>
                        <div class="form-group clearfix">
                            <input name="name" type="text" class="form-control" placeholder="Full Name" aria-label="Full Name" />
                        </div>
                        <div class="form-group clearfix">
                            <input name="email" type="email" class="form-control" placeholder="Email Address" aria-label="Email Address" />
                        </div>
                        <div class="form-group clearfix">
                            <input name="password" type="password" class="form-control" placeholder="Password" aria-label="Password" />
                        </div>
                        <div class="form-group clearfix">
                            <input name="invite" type="text" class="form-control" placeholder="Enter invite code" aria-label="Invite Code" />
                        </div>
                        <div class="form-group clearfix">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="rememberme" />
                                <label class="form-check-label" for="rememberme">
                                    I agree to the terms of service
                                </label>
                            </div>
                        </div>
                        <div class="form-group mb-0 clearfix">
                            <button type="submit" class="button-md button-theme btn-3 w-100">Register</button>
                        </div>
                    </form>
                    <p>Already a member? <Link to="/login" class="thembo"> Login here</Link></p>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

