import React from 'react';

export default function Popular(props) {
  return (

<div class="popular-places pp2 content-area-12">
    <div class="container">
        <div class="main-title">
            <h1>Popular Places</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 wow fadeInLeft delay-04s col-pad">
                <div class="row">
                    <div class="col-lg-12 col-md-6 col-sm-12">
                        <div class="popular-places-box">
                            <div class="popular-places-overflow">
                                <div class="popular-places-photo">
                                    <img class="img-fluid w-100" src="img/popular-places/popular-places-1.png" alt="popular-places" />
                                </div>
                            </div>
                            <div class="ling">
                                <div class="ling-section">
                                    <h3>
                                        <a href="#">Florida</a>
                                        <span class="float-end">27 Properties</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-6 col-sm-12">
                        <div class="popular-places-box">
                            <div class="popular-places-overflow">
                                <div class="popular-places-photo">
                                    <img class="img-fluid w-100" src="img/popular-places/popular-places-2.png" alt="popular-places" />
                                </div>
                            </div>
                            <div class="ling">
                                <div class="ling-section">
                                    <h3>
                                        <a href="#">Rome</a>
                                        <span class="float-end">12 Properties</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp delay-04s col-pad">
                <div class="popular-places-box">
                    <div class="popular-places-overflow">
                        <div class="popular-places-photo">
                            <img class="img-fluid w-100 big" src="img/popular-places/popular-places-4.png" alt="popular-places" />
                        </div>
                    </div>
                    <div class="ling">
                        <div class="ling-section">
                            <h3>
                                <a href="#">Tokyo</a>
                                <span class="float-end">06 Properties</span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 wow fadeInRight delay-04s col-pad">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="popular-places-box">
                            <div class="popular-places-overflow">
                                <div class="popular-places-photo">
                                    <img class="img-fluid w-100" src="img/popular-places/popular-places-6.png" alt="popular-places" />
                                </div>
                            </div>
                            <div class="ling">
                                <div class="ling-section">
                                    <h3>
                                        <a href="#">New York City</a>
                                        <span class="float-end">26 Properties</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="popular-places-box">
                            <div class="popular-places-overflow">
                                <div class="popular-places-photo">
                                    <img class="img-fluid w-100" src="img/popular-places/popular-places-5.png" alt="popular-places" />
                                </div>
                            </div>
                            <div class="ling">
                                <div class="ling-section">
                                    <h3>
                                        <a href="#">San Francisco</a>
                                        <span class="float-end">65 Properties</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

