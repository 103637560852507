import React from 'react';
import { Link } from 'react-router-dom';


export default function BannerProxy(props) {
  return (

<div class="banner" id="banner3">
    <div id="carouselExampleCaptions2" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item item-bg active">
                <img class="d-block w-100 h-100" src="img/banner/img-4.jpg" alt="banner-photo" />
                <div class="carousel-caption banner-slider-inner d-flex h-100">
                    <div class="container align-self-center">
                        <div class="row">
                            <div class="col-lg-7 wow fadeInLeft delay-04s align-self-center">
                                 <div class="banner-detail-box text-start">
                                    <h2 data-animation="animated fadeInDown delay-05s">Yumsights
</h2>
                                    <p data-animation="animated fadeInUp delay-1s"></p>
                                    <Link class="btn-2 btn-defaults" to="/login" data-animation="animated fadeInUp delay-15s">
                                        <span>Learn more</span> <i class="arrow"></i>
                                    </Link>
                                </div>
                           </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

