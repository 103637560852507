import React from 'react';
import { useContext } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { authContext } from './useAuth';

export default function RequireAuth( {children} ) {
  const location = useLocation();
  const auth = useContext(authContext);

  return auth.authed === true ? children : <Navigate to="/login" replace state={{ path : location.pathname }}/>;
}
