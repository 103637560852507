import React, { Component } from 'react';
import { GoogleMap, LoadScript, InfoBox, Marker } from '@react-google-maps/api';
import { authContext } from '../useAuth';


const containerStyle = {
  width:  'auto',
  height: '400px'
};

const checkboxes = [
      {
          name: 'no_rating',
          key: 'checkBox0',
          label: 'No Rating'
      },
      {
          name: 'out_of_the_way',
          key: 'checkBox1',
          label: 'Out of My Way'
      },
      {
          name: 'on_my_way',
          key: 'checkBox2',
          label: 'On My Way'
      },
      {
          name: 'no_way',
          key: 'checkBox3',
          label: 'No Way'
      }
];

export default class Map extends Component {
  static contextType = authContext;

  constructor (props) {
    super(props)

    this.state = {
       nameFilter: '',
       data: [],
       center : {
         lat: 37.572,
         lng: -122.114
       },
       zoom : 10,
       positionValid : false,
       lat : {},
       lng : {}
    }
  }

  customFilter(data) {
      return true;
  }

  handleChange = (event) => {
    //setCheckedItems({...checkedItems, [event.target.name] : event.target.checked });
    //console.log("checkedItems: ", checkedItems);
  }

  handleSearch(e) {
    e.preventDefault();
    console.log(e.target.value)
    this.setState({'nameFilter' : e.target.value});
    //setNameFilter(e.target.value.toLowerCase())
  }


  componentDidMount() {
    this.fetch();
    let context = this;

    if ("geolocation" in navigator) {
      navigator.geolocation.watchPosition(function(position) {
        context.setState({lat: position.coords.latitude});
        context.setState({lng: position.coords.longitude});
        context.setState({positionValid: true});
      }, function(error) {
        console.log(error);
        context.setState({lat: error.message});
        context.setState({lng: ''});
        context.setState({positionValid: false});
      });
    }
  }

  handleToggleOpen = (markerId) => {
    console.log("markId=" + markerId);
  }

  handleToggleClose = () => {
  }


  fetch() {
    const auth = this.context;
    auth.listRestaurant().then(data => {
       //console.log(data);
       this.setState({'data' : data})
    });
  }

  myposition() {
    if (!this.state.positionValid) {
      return;
    }

    let mypos = {
      lat : this.state.lat,
      lng : this.state.lng
    }

    return(
            <Marker key='current' 
              label='current'
              icon='markers/orange_MarkerA.png'
              position={mypos}
            >
            </Marker>
    ) 
  }

  render() {
    let dataListRaw = this.state.data
    let dataList = dataListRaw

    return (
      <div>


      <LoadScript
        googleMapsApiKey="AIzaSyCJTzxUHcFbparhXNCcjASzYnwG6fk6kGA"
      >
        <GoogleMap
          id="foodmap"
          mapContainerStyle={containerStyle}
          center={this.state.center}
          zoom={this.state.zoom}
        >
        {this.myposition()}

        {dataList
          .filter(dataItem => {
            if (dataItem.rating !== "out_of_the_way" && dataItem.rating !== "on_my_way" && dataItem.rating !== "no_way") {
              return dataItem;
            }
          })
          .map(dataItem => (
            <Marker key={dataItem.info.place_id} 
              label={dataItem.name}
              icon='markers/brown_MarkerA.png'
              position={JSON.parse(dataItem.info.location)}
              onClick={() => this.handleToggleOpen(dataItem.id)} 
            >
            </Marker>
        ))}

        {dataList
          .filter(dataItem => {
            if (dataItem.rating === "out_of_the_way") {
              return dataItem;
            }
          })
          .map(dataItem => (
            <Marker key={dataItem.info.place_id} 
              label={dataItem.name}
              icon='markers/green_MarkerA.png'
              position={JSON.parse(dataItem.info.location)}
              onClick={() => this.handleToggleOpen(dataItem.id)} 
            >
            </Marker>
        ))}

        {dataList
          .filter(dataItem => {
            if (dataItem.rating === "on_my_way") {
              return dataItem;
            }
          })
          .map(dataItem => (
            <Marker key={dataItem.info.place_id} 
              label={dataItem.name}
              icon='markers/blue_MarkerA.png'
              position={JSON.parse(dataItem.info.location)}
              onClick={() => this.handleToggleOpen(dataItem.id)} 
            >
            </Marker>
        ))}

        {dataList
          .filter(dataItem => {
            if (dataItem.rating === "no_way") {
              return dataItem;
            }
          })
          .map(dataItem => (
            <Marker key={dataItem.info.place_id} 
              label={dataItem.name}
              icon='markers/red_MarkerA.png'
              position={JSON.parse(dataItem.info.location)}
              onClick={() => this.handleToggleOpen(dataItem.id)} 
            >
            </Marker>
        ))}
        </GoogleMap>
      </LoadScript>
      <p>{JSON.stringify(this.state.lat)}</p>
      <p>{JSON.stringify(this.state.lng)}</p>

        <div class="main-title-2">
          <h1>Advanced Search</h1>
        </div>

        <h4>Search by Name</h4>
          <div class="form">
            <input type="text" name="your-name" class="form-control" placeholder="Search.." onChange={this.handleSearch}/>
          </div>
        <br />


        <h4>Restaurant Rating</h4>

        Selected entries: {dataList.filter(this.customFilter).length} out of {dataList.length}
      </div>
    )
  }
}
