import React from 'react';
import { useContext, useEffect } from "react"
import { authContext } from '../useAuth';
import { Link } from 'react-router-dom';
import $ from 'jquery';

export default function SideNav(props) {
  const auth = useContext(authContext);

  useEffect(() => {

$('#dismiss, .overlay').on('click', function () {
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
});

$('#drawer').on('click', function () {
    console.log('clicked');
    $('#sidebar').toggleClass('active');
    $('.overlay').toggleClass('active');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
});

var $ul   =   $('.sidebar-navigation > ul');
$ul.find('li a').click(function(e){
    var $li = $(this).parent();

    if($li.find('ul').length > 0){
        e.preventDefault();

        if($li.hasClass('selected')){
            $li.removeClass('selected').find('li').removeClass('selected');
            $li.find('ul').slideUp(400);
            $li.find('a em').addClass('fa-chevron-down');
            $li.find('a em').removeClass('fa-chevron-up');
        }else{

            if($li.parents('li.selected').length == 0){
                $ul.find('li').removeClass('selected');
                $ul.find('ul').slideUp(400);
                $ul.find('li a em').addClass('fa-chevron-down');
                $ul.find('li a em').removeClass('fa-chevron-up');
            }else{
                $li.parent().find('li').removeClass('selected');
                $li.parent().find('> li ul').slideUp(400);
                $li.parent().find('> li a em').addClass('fa-chevron-down');
                $li.parent().find('> li a em').removeClass('fa-chevron-up');
            }

            $li.addClass('selected');
            $li.find('>ul').slideDown(400);
            $li.find('>a>em').addClass('fa-chevron-up');
            $li.find('>a>em').removeClass('fa-chevron-down');
        }
    }
});
$('.sidebar-navigation > ul ul').each(function(i){
    if($(this).find('>li>ul').length > 0){
        var paddingLeft = $(this).parent().parent().find('>li>a').css('padding-left');
        var pIntPLeft   = parseInt(paddingLeft);
        var result      = pIntPLeft + 20;

        $(this).find('>li>a').css('padding-left',result);
    }else{
        var paddingLeft = $(this).parent().parent().find('>li>a').css('padding-left');
        var pIntPLeft   = parseInt(paddingLeft);
        var result      = pIntPLeft + 20;

        $(this).find('>li>a').css('padding-left',result).parent().addClass('selected--last');
    }
});

  }, [])


  if (!auth.authed) {
    return (
      <div></div>
    )
  } else {
    return (

<nav id="sidebar" class="nav-sidebar">
    <div id="dismiss">
        <i class="fa fa-close"></i>
    </div>

    <div class="sidebar-inner">
        <div class="sidebar-logo">
            <img src="img/logos/logo.png" alt="sidebarlogo" />
        </div>
        <div class="sidebar-navigation">
            <h3 class="heading">Pages</h3>
            <ul class="menu-list">
                <li><a href="#" class="active pt0">Index <em class="fa fa-chevron-down"></em></a>
                    <ul>
                        <li><a href="index.html">Index 1</a></li>
                        <li><a href="index-2.html">Index 2</a></li>
                        <li><a href="index-3.html">Index 3</a></li>
                        <li><a href="index-4.html">Index 4</a></li>
                        <li><a href="index-5.html">Index 5</a></li>
                        <li><a href="index-6.html">Index 6</a></li>
                    </ul>
                </li>
                <li>
                    <Link to="/addrestaurant">Add Restaurant</Link>
                </li>
                <li>
                    <a href="#">Restaurants <em class="fa fa-chevron-down"></em></a>
                    <ul>
                        <li>
                            <a href="#">Explore<em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><Link to="/restaurant">Search</Link></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Grid Layout<em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="properties-grid-rightside.html">Right Sidebar</a></li>
                                <li><a href="properties-grid-leftside.html">Left Sidebar</a></li>
                                <li><a href="properties-grid-fullwidth.html">Fullwidth</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Map View <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="properties-map-rightside-list.html">Map List 1</a></li>
                                <li><a href="properties-map-leftside-list.html">Map List 2</a></li>
                                <li><a href="properties-map-rightside-grid.html">Map Grid 1</a></li>
                                <li><a href="properties-map-leftside-grid.html">Map Grid 2</a></li>
                                <li><a href="properties-map-full.html">Map FullWidth</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Property Detail <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="properties-details.html">Property Detail 1</a></li>
                                <li><a href="properties-details-2.html">Property Detail 2</a></li>
                                <li><a href="properties-details-3.html">Property Detail 3</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">Properties <em class="fa fa-chevron-down"></em></a>
                    <ul>
                        <li>
                            <a href="#">List Layout <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="properties-list-rightside.html">Right Sidebar</a></li>
                                <li><a href="properties-list-leftside.html">Left Sidebar</a></li>
                                <li><a href="properties-list-fullwidth.html">Fullwidth</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Grid Layout<em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="properties-grid-rightside.html">Right Sidebar</a></li>
                                <li><a href="properties-grid-leftside.html">Left Sidebar</a></li>
                                <li><a href="properties-grid-fullwidth.html">Fullwidth</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Map View <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="properties-map-rightside-list.html">Map List 1</a></li>
                                <li><a href="properties-map-leftside-list.html">Map List 2</a></li>
                                <li><a href="properties-map-rightside-grid.html">Map Grid 1</a></li>
                                <li><a href="properties-map-leftside-grid.html">Map Grid 2</a></li>
                                <li><a href="properties-map-full.html">Map FullWidth</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Property Detail <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="properties-details.html">Property Detail 1</a></li>
                                <li><a href="properties-details-2.html">Property Detail 2</a></li>
                                <li><a href="properties-details-3.html">Property Detail 3</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">Agents <em class="fa fa-chevron-down"></em></a>
                    <ul>
                        <li><a href="agent-listing-grid.html">Agent grid</a></li>
                        <li><a href="agent-listing-grid-sidebar.html">Agent Grid sidebarbar</a></li>
                        <li><a href="agent-listing-row.html">Agent list</a></li>
                        <li><a href="agent-listing-row-sidebar.html">Agent List Sidebarbar</a></li>
                        <li><a href="agent-single.html">Agent Detail</a></li>
                    </ul>
                </li>
                <li>
                    <a href="#">Pages <em class="fa fa-chevron-down"></em></a>
                    <ul>
                        <li>
                            <a href="#">About<em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="about.html">About 1</a></li>
                                <li><a href="about-2.html">About 2</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Services<em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="services-1.html">Services 1</a></li>
                                <li><a href="services-2.html">Services 2</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Pricing Tables <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="pricing-tables.html">Pricing Tables 1</a></li>
                                <li><a href="pricing-tables-2.html">Pricing Tables 2</a></li>
                                <li><a href="pricing-tables-3.html">Pricing Tables 3</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Faq <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="faq.html">Faq 1</a></li>
                                <li><a href="faq-2.html">Faq 2</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Gallery<em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="gallery-1.html">Gallery 1</a></li>
                                <li><a href="gallery-2.html">Gallery 2</a></li>
                                <li><a href="gallery-3.html">Gallery 3</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Contact <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="contact.html">Contact 1</a></li>
                                <li><a href="contact-2.html">Contact 2</a></li>
                                <li><a href="contact-3.html">Contact 3</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="properties-comparison.html">Properties Comparison</a>
                        </li>
                        <li>
                            <a href="search-brand.html">Search Brand</a>
                        </li>
                        <li>
                            <a href="typography.html">Typography</a>
                        </li>
                        <li>
                            <a href="elements.html">Elements</a>
                        </li>
                        <li>
                            <a href="icon.html">Icon</a>
                        </li>
                        <li>
                            <a href="404.html">Pages 404</a>
                        </li>
                        <li>
                            <a href="user-profile.html">User Profile</a>
                        </li>
                        <li>
                            <a href="my-properties.html">My Properties</a>
                        </li>
                        <li>
                            <a href="favorited-properties.html">Favorited properties</a>
                        </li>
                        <li>
                            <a href="login.html">Login</a>
                        </li>
                        <li>
                            <a href="signup.html">Signup</a>
                        </li>
                        <li>
                            <a href="forgot-password.html">Forgot Password</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">Blog <em class="fa fa-chevron-down"></em></a>
                    <ul>
                        <li>
                            <a href="#">Blog Classic <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="blog-classic-sidebar-right.html">Right Sidebar</a></li>
                                <li><a href="blog-classic-sidebar-left.html">Left Sidebar</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Blog Columns <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="blog-columns-2col.html">2 Columns</a></li>
                                <li><a href="blog-columns-3col.html">3 Columns</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Blog Details <em class="fa fa-chevron-down"></em></a>
                            <ul>
                                <li><a href="blog-single-sidebar-right.html">Right Sidebar</a></li>
                                <li><a href="blog-single-sidebar-left.html">Left Sidebar</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="submit-property.html">Submit Property</a>
                </li>
            </ul>
        </div>
    </div>
</nav>

    )
  }
}

