import React from 'react';
import { useContext } from "react"
import { Link } from 'react-router-dom';
import { authContext } from '../useAuth';

import Version from './Version';

export default function Header(props) {
  const auth = useContext(authContext);
  
  if (!auth.authed) {
    return (

<header class="top-header" id="top">
   <div class="container">
        <div class="row">
            <div class="col-7 col-sm-7 col-md-7 col-lg-6">
            </div>
            <div class="col-5 col-sm-5 col-md-5 col-lg-6">
                <ul class="top-social-media pull-right">
                    <li>
                        <Link to="/login" class="sign-in"><i class="fa fa-sign-in"></i> Login</Link>
                    </li>
                    <li>
                        <Link to="/signup" class="sign-in"><i class="fa fa-sign-in"></i> Register</Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>

    )

  } else {
    return (

<header class="top-header" id="top">
   <div class="container">
        <div class="row">
            <div class="col-7 col-sm-7 col-md-7 col-lg-6">
                <div class="list-inline">
                  <Link to="/" class="logo">
                    <img src="img/logos/logo.png" alt="logo" width="100px"/>
                  </Link>
                  <Version />
                </div>
            </div>
            <div class="col-5 col-sm-5 col-md-5 col-lg-6">
                <ul class="top-social-media pull-right">
                    <li>
                        <Link to="inbox"><i class="fa fa-inbox"></i></Link>
                    </li>
                    <li>
                        <Link to="notifications"><i class="fa fa-bell-o"></i></Link>
                    </li>
                    <li>
                      <Link to="/user"><i class="fa fa-user"></i>{auth.username}</Link>
                    </li>
                    <li>
                        <Link to="/logout" class="sign-in"><i class="fa fa-sign-out"></i> Logout</Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>
    )
  }
}

