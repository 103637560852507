import React from 'react';
import { useContext } from "react"
import { authContext } from '../useAuth';
import { Link } from 'react-router-dom';

export default function Footer(props) {
  const auth = useContext(authContext);

  if (!auth.authed) {
    return (

<footer class="main-footer clearfix">
    <div class="container">
        <div class="footer-info">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div class="main-title-2">
                            <h1>Contact Us</h1>
                        </div>
                        <ul class="personal-info">
                            <li>
                                <i class="fa fa-envelope"></i>
                                Email:<a href="mailto:info@yumsights.com"> info@yumsights.com</a>
                            </li>
                        </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copy-right">
        <div class="container">
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <p>&copy;  2021 <a href="http://yumsights.com/" target="_blank">Yumsights</a>. All Rights Reserved.</p>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <ul class="social-list clearfix">
                        <li>
                            <a href="#" class="facebook-bg">
                                <i class="fa fa-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="twitter-bg">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="google-bg">
                                <i class="fa fa-google-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="rss-bg">
                                <i class="fa fa-rss"></i>
                           </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

    )

  } else {
    return (

<footer class="main-footer clearfix">
    <div class="container">

        <div class="footer-info">
            <div class="row">

                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div class="footer-item fi2">
                        <div class="main-title-2">
                            <h1>Contact Us</h1>
                        </div>
                        <ul class="personal-info">
                            <li>
                                <i class="fa fa-envelope"></i>
                                Email:<a href="mailto:info@yumsights.com"> info@yumsights.com</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                    <div class="footer-item">
                        <div class="main-title-2">
                            <h1>Links</h1>
                        </div>
                        <ul class="links">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/about">About</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/blog">Blog</Link>
                            </li>
                            <li>
                                <Link to="/restaurant-list">Restaurant List</Link>
                            </li>
                            <li>
                                <Link to="/restaurant-details">Restaurant Details</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="footer-item popular-posts">
                        <div class="main-title-2">
                            <h1>Popular Posts</h1>
                        </div>
                        <div class="d-flex mb-3 popular-posts-box">
                            <a class="pr-3" href="properties-details.html">
                                <img src="img/properties/small-properties-2.png" alt="small-photo" class="flex-shrink-0 me-3" />
                            </a>
                            <div class="detail align-self-center">
                                <h4>
                                    <a href="properties-details.html">Modern Family Home</a>
                                </h4>
                                <div class="listing-post-meta">
                                    Sep 18, 2021 | <a href="#">$470,00</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mb-3 popular-posts-box">
                            <a class="pr-3" href="properties-details.html">
                                <img src="img/properties/small-properties-1.png" alt="small-photo" class="flex-shrink-0 me-3" />
                            </a>
                            <div class="detail align-self-center">
                                <h4>
                                    <a href="properties-details.html">Sweet Family Home</a>
                                </h4>
                                <div class="listing-post-meta">
                                    Aug 18, 2020 | <a href="#">$485,00</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex popular-posts-box">
                            <a class="pr-3" href="properties-details.html">
                                <img src="img/properties/small-properties-3.png" alt="small-photo" class="flex-shrink-0 me-3" />
                            </a>
                            <div class="detail align-self-center">
                                <h4>
                                    <a href="properties-details.html">Beautful Single Home</a>
                                </h4>
                                <div class="listing-post-meta">
                                    Aug Feb, 2021 | <a href="#">$850,00</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="footer-item">
                        <div class="main-title-2">
                            <h1>Suggestion Box</h1>
                        </div>
                        <div class="newsletter clearfix">
                            <p>Have a suggestion or feedback on the website?  Send us a note!</p>
                            <form class="form-inline d-flex" action="#">
                                <input class="form-control" type="text" id="suggestion" placeholder="Comments.." />
                                <button class="btn button-theme" type="submit"><i class="fa fa-paper-plane"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copy-right">
        <div class="container">
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <p>&copy;  2021 <a href="http://yumsights.com/" target="_blank">Yumsights</a>. All Rights Reserved.</p>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <ul class="social-list clearfix">
                        <li>
                            <a href="#" class="facebook-bg">
                                <i class="fa fa-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="twitter-bg">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="google-bg">
                                <i class="fa fa-google-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="rss-bg">
                                <i class="fa fa-rss"></i>
                           </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

    )
  }
}

