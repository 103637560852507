import React from 'react';
import ReactGA from 'react-ga';
import { useState, useEffect } from "react"
import { v4 as uuidv4 } from 'uuid';
import * as gameserver from "@heroiclabs/nakama-js";

export const authContext = React.createContext();

var useSSL = true;
var client = new gameserver.Client("MFatBDsvqsrx9YPuslk3jgRYXiLlpCjU", "yumsights.com", "7350", useSSL);

const system_user_id = '8c271db4-302e-48d2-8c0f-8d0c55542bfd';

function useAuth() {
  const [authed, setAuthed] = useState(false);
  const [username, setUsername] = useState('');
  const [admin, setAdmin] = useState(false);
  const [mysession, setMysession] = useState({});
  const [database, setDatabase] = useState([]);

  //----------------------------------------------------------------------
  // List restaurant2 (TEMP) 
  //----------------------------------------------------------------------
  /*
  async function listRestaurant2() {
    var json = [];

    //---------------------------------------------
    // Get restaurant user information
    //---------------------------------------------
    var results = await listdata("restaurant");
    console.log("LISTRESTAURANT RESULTS:");
    //console.log(results);

    if (results && results.objects.length > 0) {
      var data = results.objects; 
      //console.log(data);
      for (var i=0; i<data.length; i++) {
        //console.log("PARSE:" + i);
        //console.log(data[i].key);

        let restaurant = await getRestaurant(data[i].key);
        //console.log(restaurant);
        json.push(restaurant);
      }
    }
    setDatabase(json);
    return json;
  }
  */

  //----------------------------------------------------------------------
  // Admin Check  
  //----------------------------------------------------------------------
  function adminCheck(session) {
    if (session.username === 'jcheung') {
      console.log("Setting administration privileges");
      setAdmin(true); 
    }  else {
      console.log("Normal user");
      setAdmin(false); 
    }
  }

  //----------------------------------------------------------------------
  // Recent restaurant 
  //----------------------------------------------------------------------
  async function recentRestaurant() {
    var restaurant_list = [];
    var restaurant_cnt;
    var json = [];

    //---------------------------------------------
    // Get restaurant user information
    //---------------------------------------------
    var results = await listdata("restaurant");
    //console.log("LISTRESTAURANT RESULTS:");
    //console.log(results);

    if (results && results.objects.length > 0) {

      results.objects.sort((a,b) => b.update_time.localeCompare(a.update_time));

      var data = results.objects; 
      console.log(data);
      data.length = 15;

      restaurant_cnt = data.length;

      for (var i=0; i<data.length; i++) {
        //console.log(data[i]);
        //console.log(i + ' ' + data[i].value.restaurant_id);
        restaurant_list.push({"collection":"restaurant_info", "key":data[i].value.restaurant_id,"user_id":mysession.user_id});
      }

      var restaurant_info = await read_user_data(mysession, restaurant_list);
      //console.log("RESTAURANT_INFO");
      //console.log(restaurant_info);

      if (restaurant_cnt !== restaurant_info.objects.length) {
        console.log("ERROR, missing restaurant info, listrestaurant=" + restaurant_cnt +  " but found=" + restaurant_info.objects.length);
        return({'error': 'restaurant_info missing'});
      }

      if (restaurant_info && restaurant_info.objects.length > 0) {
        for (let i=0; i<restaurant_info.objects.length; i++) {
          let key = data[i].key;
          //console.log(i + ": DATA_KEY=" + key);
          const match = restaurant_info.objects.filter(entry => entry.key === key);
          //console.log(match);
          data[i].value.name = match[0].value.name;
          data[i].value.info = match[0].value;
          json.push(data[i].value);
        }
      }
    }
    return json;
  }

  //----------------------------------------------------------------------
  // List restaurant 
  //----------------------------------------------------------------------
  async function listRestaurant() {
    var restaurant_list = [];
    var restaurant_cnt;
    var json = [];

    //---------------------------------------------
    // Get restaurant user information
    //---------------------------------------------
    var results = await listdata("restaurant");
    //console.log("LISTRESTAURANT RESULTS:");
    //console.log(results);

    if (results && results.objects.length > 0) {
      var data = results.objects; 
      //console.log(data);
      restaurant_cnt = data.length;;
      for (var i=0; i<data.length; i++) {
        //console.log(data[i]);
        //console.log(i + ' ' + data[i].value.restaurant_id);
        restaurant_list.push({"collection":"restaurant_info", "key":data[i].value.restaurant_id,"user_id":mysession.user_id});
      }

      var restaurant_info = await read_user_data(mysession, restaurant_list);
      //console.log("RESTAURANT_INFO");
      //console.log(restaurant_info);

      if (restaurant_cnt !== restaurant_info.objects.length) {
        console.log("ERROR, missing restaurant info, listrestaurant=" + restaurant_cnt +  " but found=" + restaurant_info.objects.length);
        return({'error': 'restaurant_info missing'});
      }

      if (restaurant_info && restaurant_info.objects.length > 0) {
        for (let i=0; i<restaurant_info.objects.length; i++) {
          let key = data[i].key;
          //console.log(i + ": DATA_KEY=" + key);
          const match = restaurant_info.objects.filter(entry => entry.key === key);
          //console.log(match);
          data[i].value.name = match[0].value.name;
          data[i].value.info = match[0].value;
          json.push(data[i].value);
        }
      }
    }
    return json;
  }

  //----------------------------------------------------------------------
  // Add restaurant 
  //----------------------------------------------------------------------
  async function addRestaurant(data) {
    var restaurant_id = uuidv4();
    var querydata = {};
    var results;

    querydata = {
      "name" : data.name,
      "address" : data.address,
      "location" : data.location,
      "place_id" : data.place_id,
      "cuisine" : data.cuisine
    }
    results = await writedata('restaurant_info',restaurant_id,querydata);
    console.log(results);

    querydata = {
      "restaurant_id" : restaurant_id,
      "rating" : "no_rating",
      "score" : 0,
      "description" : data.description,
      "tags" : "",
      "photos_id" : [],
      "sigdish_id" : [],
      "referrer" : ""
    }
    results = await writedata('restaurant',restaurant_id,querydata);
    console.log(results);
    return(restaurant_id);
  }

  //----------------------------------------------------------------------
  // Get restaurant JSON
  //----------------------------------------------------------------------
  async function getRestaurant(restaurant_id) {
    var json = {};
    var querydata = []; 
    var results = {}; 
    var data = {}; 
    var photos_id = [];
    var sigdish_id = [];

    //---------------------------------------------
    // Get restaurant user information
    //---------------------------------------------
    querydata = [{
      'collection' : "restaurant",
      'key' : restaurant_id,
      'user_id' : mysession.user_id
    }]
    results = await read_user_data(mysession, querydata);
    //console.log("GET RESTAURANT RESULTS:");
    //console.log(results);

    if (results && results.objects.length === 1) {
      data = results.objects[0].value; 
      console.log(data);
      photos_id  = data.photos_id;
      sigdish_id = data.sigdish_id;
      json = data;
      
      //----------------------------------------
      // Get common restaurant info
      //----------------------------------------
      querydata = [{
        'collection' : "restaurant_info",
        'key' : data.restaurant_id,
        'user_id' : mysession.user_id
      }]
      results = await read_user_data(mysession, querydata);
      //console.log(results);

      if (results && results.objects.length === 1) {
        data = results.objects[0].value; 
        //console.log(data);
        json.info = data;
      }

      //----------------------------------------
      // Get Photos
      //----------------------------------------
      //console.log("PHOTO ID");
      //console.log(photos_id);

      var queryphoto = [];
      for (var i in photos_id) {
         //console.log('id=' + photos_id[i]);
         querydata = {
          'collection' : "photos",
          'key' : photos_id[i],
          'user_id' : mysession.user_id
         }
         queryphoto.push(querydata);
      }
     
      results = await read_user_data(mysession, queryphoto);
      //console.log(results);

      json.photos = [];
      if (results && results.objects.length > 0) {
        data = results.objects; 
        //console.log(data);
        for (let i=0; i<data.length; i++) {
          //console.log(data[i]);
          json.photos.push(data[i].value);;
        }
      }

      //----------------------------------------
      // Get Signature Dishes
      //----------------------------------------
      //console.log("SIGDISH ID");
      //console.log(sigdish_id);

      var querysigdish = [];
      for (let i in sigdish_id) {
         //console.log('id=' + sigdish_id[i]);
         querydata = {
          'collection' : "sigdish",
          'key' : sigdish_id[i],
          'user_id' : mysession.user_id
         }
         querysigdish.push(querydata);
      }
     
      results = await read_user_data(mysession, querysigdish);
      //console.log(results);

      json.sigdish = [];
      if (results && results.objects.length > 0) {
        data = results.objects; 
        //console.log(data);
        for (let i=0; i<data.length; i++) {
          //console.log(data[i]);
          json.sigdish.push(data[i].value);;
        }
      }

    }
    console.log("Final JSON");
    console.log(json);
    return json;
  }

  //----------------------------------------------------------------------
  // Update Restaurant
  //----------------------------------------------------------------------
  async function updateRestaurant(restaurant_id, data) {
    var results;

    results = await readdata('restaurant',restaurant_id);

    console.log("UPDATE");
    console.log(results);
    for (const key of Object.keys(data)) {
      console.log(key, data[key]);
      if (key in results) {
        results[key] = data[key];
      }
    }

    writedata('restaurant',restaurant_id, results);
  }

  //----------------------------------------------------------------------
  // Update Restaurant Info
  //----------------------------------------------------------------------
  async function updateRestaurantInfo(restaurant_id, data) {
    var results;

    results = await readdata('restaurant_info',restaurant_id);

    console.log("UPDATE");
    console.log(results);
    for (const key of Object.keys(data)) {
      console.log(key, data[key]);
      if (key in results) {
        results[key] = data[key];
      }
    }

    writedata('restaurant_info',restaurant_id, results);
  }

  //----------------------------------------------------------------------
  // Delete Restaurant
  //----------------------------------------------------------------------
  async function delRestaurant(restaurant_id) {
    var results;

    results = await readdata('restaurant',restaurant_id);
    console.log("DELETE RESTAURANT")
    console.log(results)

    for (var i in results.sigdish_id) {
      let sigdish_id = results.sigdish_id[i]
      console.log('sigdish_id=' + sigdish_id);
      deletedata('sigdish', sigdish_id);
    }

    for (var i in results.photos_id) {
      let photos_id = results.photos_id[i]
      console.log('photos_id=' + photos_id);
      deletedata('photos', photos_id);
    }

    deletedata('restaurant', restaurant_id);
    deletedata('restaurant_info', restaurant_id);
  }

  //----------------------------------------------------------------------
  // List photo 
  //----------------------------------------------------------------------
  async function listPhoto() {
    var json = [];

    var results = await listdata("photos");
    //console.log("PHOTOS RESULTS:");
    //console.log(results);

    if (results && results.objects.length > 0) {
      var data = results.objects; 
      //console.log(data);
      for (var i=0; i<data.length; i++) {
        //console.log(data[i]);
        data[i].value.id = data[i].key;
        json.push(data[i].value);
      }
    }
    return json;
  }

  //----------------------------------------------------------------------
  // Add photo 
  //----------------------------------------------------------------------
  async function addPhoto(restaurant_id, data) {
    var id = uuidv4();
    var querydata = {};
    var results;

    var restaurant_name;
    if (restaurant_id !== 0) {
      results = await readdata('restaurant',restaurant_id);
      results.photos_id.push(id);
      writedata('restaurant',restaurant_id, results);

      results = await readdata('restaurant_info',restaurant_id);
      restaurant_name = results.name;
    }
    
    querydata = {
      "filename" : data.filename,
      "url" : data.url,
      "date" : data.date,
      "orientation" : data.orientation,
      "description" : data.description,
      "sigdish_id" : '',
      "sigdish_name" : '',
      "restaurant_id" : restaurant_id,
      "restaurant_name" :restaurant_name 
    }
    writedata('photos',id, querydata);
    return(id);
  }

  //----------------------------------------------------------------------
  // Update Photo
  //----------------------------------------------------------------------
  async function updatePhoto(id, data) {
    var results;

    results = await readdata('photos',id);

    console.log("UPDATE");
    console.log(results);
    for (const key of Object.keys(data)) {
      console.log(key, data[key]);
      if (key in results) {
        results[key] = data[key];
      }
    }

    writedata('photos',id, results);
  }

  //----------------------------------------------------------------------
  // Update Photo Map
  //----------------------------------------------------------------------
  async function updatePhotoMap(photo_id, restaurant_id, sigdish_id) {
    var data = {};
    var photo_id_list;
    var restaurant_results;
    var restaurantinfo_results;
    var sigdish_results;
    var photo_results;

    restaurant_results = await readdata('restaurant',restaurant_id);
    restaurantinfo_results = await readdata('restaurant_info',restaurant_id);
    photo_results = await readdata('photos',photo_id);

    //------------------------------------------------------
    // Clear original restaurant reference to photo
    //------------------------------------------------------
    console.log("CHECK EXISTING");
    console.log(photo_results.restaurant_id);    
    console.log(photo_results.sigdish_id);    

    if (photo_results.restaurant_id !== '') {
      var original_restaurant_results = await readdata('restaurant',photo_results.restaurant_id);
      console.log(original_restaurant_results);

      data = {};
      photo_id_list = original_restaurant_results.photos_id;
      console.log("BEFORE");
      console.log(photo_id_list);

      if (photo_id_list.includes(photo_id)) {
        // Remove photo_id from list
        for(let i=0; i<photo_id_list.length; i++){ 
          if (photo_id_list[i] === photo_id) { 
              photo_id_list.splice(i, 1); 
              i--; 
          }
        }
        data['photos_id'] = photo_id_list;
        console.log("CLEAR RESTAURANT PHOTO_ID LIST");
        console.log(data);
        updateRestaurant(photo_results.restaurant_id, data);

      } else { 
        console.log("Error : inconsistent database");
      }
    }

    //------------------------------------------------------
    // Update Photo Collection
    //------------------------------------------------------
    data = {};
    data['restaurant_id'] = restaurant_id;
    data['restaurant_name'] = restaurantinfo_results.name;

    if (sigdish_id !== '') {
      sigdish_results = await readdata('sigdish',sigdish_id);
      data['sigdish_id'] = sigdish_id;
      data['sigdish_name'] = sigdish_results.name;
    }

    console.log("UPDATE PHOTO COLLECTION");
    console.log(data);
    updatePhoto(photo_id, data);

    //------------------------------------------------------
    // Update Restaurant
    //------------------------------------------------------
    data = {};
    photo_id_list = restaurant_results.photos_id;
    if (photo_id_list.includes(photo_id)) {
      console.log("Photo already there");
    } else { 
      console.log("Adding photo " + photo_id + " to list");
      photo_id_list.push(photo_id);
    }
    data['photos_id'] = photo_id_list;
    console.log("UPDATE RESTAURANT PHOTO_ID LIST");
    console.log(data);
    updateRestaurant(restaurant_id, data);
  }

  //----------------------------------------------------------------------
  // List Signature Dish 
  //----------------------------------------------------------------------
  async function listSigDish() {
    var json = [];

    var results = await listdata("sigdish");
    console.log("SIGDISH RESULTS:");
    console.log(results);

    if (results && results.objects.length > 0) {
      var data = results.objects; 
      console.log(data);
      for (var i=0; i<data.length; i++) {
        console.log(data[i]);
        data[i].value.id = data[i].key;
        json.push(data[i].value);
      }
    }
    return json;
  }

  //----------------------------------------------------------------------
  // Add Signature Dish
  //----------------------------------------------------------------------
  async function addSigDish(restaurant_id, data) {
    var id = uuidv4();
    var querydata = {};
    var results;

    results = await readdata('restaurant',restaurant_id);
    results.sigdish_id.push(id);
    writedata('restaurant',restaurant_id, results);
    
    querydata = {
      "id" : id,
      "name" : data.name,
      "description" : data.description,
      "rating" : "no_rating",
      "score" : 0,
      "dish" : data.dish,
      "photos_id" : []
    }
    writedata('sigdish',id, querydata);
    return(id);
  }

  //----------------------------------------------------------------------
  // Update Signature Dish
  //----------------------------------------------------------------------
  async function updateSigDish(sigdish_id, data) {
    var results;

    results = await readdata('sigdish',sigdish_id);

    console.log("UPDATE");
    console.log(results);
    for (const key of Object.keys(data)) {
      console.log(key, data[key]);
      if (key in results) {
        results[key] = data[key];
      }
    }

    writedata('sigdish',sigdish_id, results);
  }

  //----------------------------------------------------------------------
  // Delete Signature Dish
  //----------------------------------------------------------------------
  async function delSigDish(restaurant_id, sigdish_id) {
    console.log("DELETE DISH")
    console.log(restaurant_id)
    console.log(sigdish_id)
   
    var results;

    //console.log(results);
    results = await readdata('restaurant',restaurant_id);
    results.sigdish_id = arrayRemove(results.sigdish_id, sigdish_id)
    //console.log(results);

    writedata('restaurant',restaurant_id, results);
    deletedata('sigdish', sigdish_id);
  }

  //----------------------------------------------------------------------
  // List cuisines 
  //----------------------------------------------------------------------
  async function listCuisine() {
    var json = [];

    //---------------------------------------------
    // Get cuisine list
    //---------------------------------------------
    var results = await listdata_user("cuisine", system_user_id);
    //console.log("CUISINES RESULTS:");
    //console.log(results);

    if (results && results.objects.length > 0) {
      var data = results.objects; 
      //console.log(data);
      for (var i=0; i<data.length; i++) {
        //console.log(data[i]);
        json.push({'name': data[i].key, 'dish':data[i].value.dish});
      }
    }
    return json;
  }

  //----------------------------------------------------------------------
  // Add cuisine 
  //----------------------------------------------------------------------
  async function addCuisine(cuisine) {
    var querydata = {};

    querydata = {
      "dish" : ['other']
    }
    writedata_public('cuisine',cuisine, querydata);
  }

  //----------------------------------------------------------------------
  // List dish 
  //----------------------------------------------------------------------
  async function listDish(cuisine) {
    var json = [];
    var results;

    //console.log("searching for " + cuisine);
    results = await readdata_user('cuisine', cuisine, system_user_id);
    //console.log("LISTDISH");
    //console.log(results);

    if ('dish' in results) {
      for (var i=0; i<results.dish.length; i++) {
        //console.log(results.dish[i]);
        json.push({'name': results.dish[i]});
      }
    }
    return json;
  }

  //----------------------------------------------------------------------
  // Add dish 
  //----------------------------------------------------------------------
  async function addDish(cuisine, dish) {
    var results;

    results = await readdata('cuisine', cuisine);
    console.log(results);
    results.dish.push(dish);
    writedata_public('cuisine',cuisine, results);
  }

  //----------------------------------------------------------------------
  // Add yumlink 
  //----------------------------------------------------------------------
  async function addYumlink(data) {
    var id = uuidv4();
    var querydata = {};
    var results;

    querydata = {
      "id" : id,
      "type" : "restaurant_list",
      "name" : data.name,
      "restaurant_id" : data.restaurant_id,
      "url"  : "http://yumsights.com:3000/yl/" + id
    }
    results = await writedata('yumlink',id,querydata);
    console.log(results);

    return(id);
  }

  //----------------------------------------------------------------------
  // List yumlink 
  //----------------------------------------------------------------------
  async function listYumlink() {
    var json = [];

    //---------------------------------------------
    // Get yumlink list
    //---------------------------------------------
    var results = await listdata("yumlink");
    console.log("YUMLINK RESULTS:");
    console.log(results);

    if (results && results.objects.length > 0) {
      var data = results.objects; 
      console.log(data);
      for (var i=0; i<data.length; i++) {
        console.log(data[i]);
        json.push({'id': data[i].value.id, 'type': data[i].value.type, 'url':data[i].value.url,'name':data[i].value.name,'restaurant_id':data[i].value.restaurant_id});
      }
    }
    return json;
  }

  //----------------------------------------------------------------------
  // List Leaderboard 
  //----------------------------------------------------------------------
  async function listLeaderboard() {
    //---------------------------------------------
    // Get leaderboard
    //---------------------------------------------
    console.log("LEADERBOARD RESULTS:");
    var leaderboardId = "level1";
    var result = await client.listLeaderboardRecords(mysession, leaderboardId);
    result.records.forEach(function(record) {
      console.log("Record username %o and score %o", record.username, record.score);
    });
    return(result.records)
  }

  //----------------------------------------------------------------------
  // Add Leaderboard Record 
  //----------------------------------------------------------------------
  async function addLeaderboardRecord() {
    //---------------------------------------------
    // Get leaderboard
    //---------------------------------------------
    console.log("ADD LEADERBOARD RECORD");

    var leaderboardId = "level1";
    var submission = {score: 200};
    let session = mysession;
    console.log(session);
    //session.user_id = '1111';
    session.username = 'myrestaurant';

    var record = await client.writeLeaderboardRecord(session, leaderboardId, submission);
    console.log("New record username %o and score %o", record.username, record.score);
  }


  //----------------------------------------------------------------------
  // login
  //----------------------------------------------------------------------
  async function login(username, email, password) {
    console.log("login function");
    var results = await loginUser(username, email, password).then(session => {
      console.log("LOGIN USER RESULTS");
      console.log(session);
      if (!session) {
        logout();
      } else {
        setAuthed(true);
        setUsername(session.username);
        adminCheck(session);
        setMysession(session);
        ReactGA.set({UserID: session.user_id});
      }
    });
    return results;
  } 

  //----------------------------------------------------------------------
  // logout
  //----------------------------------------------------------------------
  async function logout() {
     console.log("logout function");
     logoutUser();
     setAuthed(false);
     setUsername('');
     setAdmin(false);
     setMysession({});
  } 

  //----------------------------------------------------------------------
  // register
  //----------------------------------------------------------------------
  async function register(username, email, password) {
    var results = await registerUser(username, email, password).then(session => {
      console.log("REGISTER USER RESULTS");
      console.log(session);
      if (!session) {
        logout();
      } else {
        setAuthed(true);
        setUsername(session.username);
        adminCheck(session);
        setMysession(session);
        ReactGA.set({UserID: session.user_id});
      }
    });
    return results;
  }

  //----------------------------------------------------------------------
  // account
  //----------------------------------------------------------------------
  async function account() {
    var results = await get_account(mysession);
    console.log("ACCOUNT RESULTS");
    console.log(results);
    return(results);
  }

  //----------------------------------------------------------------------
  // listdata
  //----------------------------------------------------------------------
  async function listdata(collection) {
    var results = await list_data(mysession, collection, mysession.user_id);
    //console.log("LISTDATA RESULTS");
    //console.log(results);
    return(results);
  }

  //----------------------------------------------------------------------
  // listdata_user
  //----------------------------------------------------------------------
  async function listdata_user(collection, user_id) {
    var results = await list_data(mysession, collection, user_id);
    //console.log("LISTDATA RESULTS");
    //console.log(results);
    return(results);
  }


  //----------------------------------------------------------------------
  // readdata
  //----------------------------------------------------------------------
  async function readdata(collection, key) {
    var querydata = [{
      'collection' : collection,
      'key' : key,
      'user_id' : mysession.user_id
    }]
    var results = await read_user_data(mysession, querydata);
    //console.log("READDATA RESULTS");
    //console.log(results);

    var data = {};
    if (results && results.objects.length === 1) {
      data = results.objects[0].value; 
    }      
    return(data);
  }

  //----------------------------------------------------------------------
  // readdata_user
  //----------------------------------------------------------------------
  async function readdata_user(collection, key, user_id) {
    var querydata = [{
      'collection' : collection,
      'key' : key,
      'user_id' : user_id
    }]
    var results = await read_user_data(mysession, querydata);
    //console.log("READDATA RESULTS");
    //console.log(results);

    var data = {};
    if (results && results.objects.length === 1) {
      data = results.objects[0].value; 
    }      
    return(data);
  }

  //----------------------------------------------------------------------
  // writedata
  //----------------------------------------------------------------------
  async function writedata(collection, key, value) {
    var data = [{
      'collection' : collection,
      'key' : key,
      'value' : value
    }]
    var results = await write_user_data(mysession, data);
    //console.log("WRITE RESULTS");
    //console.log(results);
    return(results);
  }

  //----------------------------------------------------------------------
  // writedata_public
  //----------------------------------------------------------------------
  async function writedata_public(collection, key, value) {
    var data = [{
      'collection' : collection,
      'key' : key,
      'value' : value,
      'permission_read' : 2,
      'permission_write' : 1
    }]
    var results = await write_user_data(mysession, data);
    //console.log("WRITE RESULTS");
    //console.log(results);
    return(results);
  }

  //----------------------------------------------------------------------
  // deletedata
  //----------------------------------------------------------------------
  async function deletedata(collection, key) {
    var data = [{
      'collection' : collection,
      'key' : key
    }]
    var results = await delete_user_data(mysession, data);
    console.log("DELETE RESULTS");
    return(true);
  }

  useEffect(() => {
    restoreSession().then(session => {
      console.log("Restore session results");
      console.log(session);
      if (session) {
        setAuthed(true);
        setUsername(session.username);
        adminCheck(session);
        setMysession(session);
        ReactGA.set({UserID: session.user_id});
      }
    });

    return () => {
    };
  }, []);

  return {authed, username, admin, database, login, logout, register, account, listdata, listdata_user, writedata, writedata_public, readdata, readdata_user, deletedata, recentRestaurant, listRestaurant, addRestaurant, getRestaurant, updateRestaurant, updateRestaurantInfo, delRestaurant, listPhoto, addPhoto, updatePhoto, updatePhotoMap, listSigDish, addSigDish, updateSigDish, delSigDish, listCuisine, addCuisine, listDish, addDish, addYumlink, listYumlink, listLeaderboard, addLeaderboardRecord};
}


export function AuthProvider( {children} ) {
  const auth = useAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function AuthConsumer() {
  return React.useContext(authContext);
}


//-----------------------------------------------------------------
// Authentication Functions
//-----------------------------------------------------------------
async function storeSession(session) {
  if (typeof(Storage) !== "undefined") {
    localStorage.setItem("nakamaToken", session.token);
    console.log("Session stored.");
  };
}

async function getSessionFromStorage() {
  if (typeof(Storage) !== "undefined") {
    return Promise.resolve(localStorage.getItem("nakamaToken"));
  }
}

async function clearSessionFromStorage() {
  if (typeof(Storage) !== "undefined") {
    return Promise.resolve(localStorage.removeItem("nakamaToken"));
  }
}

async function registerUser(username, email, password) {
  var session = null;
  var create = true;
  try {
    session = await client.authenticateEmail(email, password, create, username);
    storeSession(session);
    console.log("New User registered successfully. User ID: %o", session.user_id);
    return session;
  } catch(e) {
    console.log("An error occured while trying to create new user: %o", e)
    return null;
  }
}

async function loginUser(username, email, password) {
  var session = null;
  var create = false;
  try {
    session = await client.authenticateEmail(email, password, create, username);
    storeSession(session);
    console.log("User logged in successfully. User ID: %o", session.user_id);
    return session;
  } catch(e) {
    console.log("An error occured while trying to create new user: %o", e)
    return null;
  }
}

async function logoutUser() {
  try {
    clearSessionFromStorage()
    console.log("Session cleared");
  } catch(e) {
    console.log("An error occured while trying to clear session: %o", e)
  }
}

async function restoreSession() {
  var session = null;

  try {
    var sessionString = await getSessionFromStorage();
    console.log("session str=" + sessionString)
    if (sessionString && sessionString !== "") {
      session = gameserver.Session.restore(sessionString);
      var currentTimeInSec = new Date() / 1000;
      if (!session.isexpired(currentTimeInSec)) {
        console.log("Restored session. User ID: %o", session.user_id);
        return Promise.resolve(session);
      } else {
        clearSessionFromStorage();
        console.log("Expired session");
        return Promise.resolve(null);
      }
    } else {
      console.log("No session token found");
      return Promise.resolve(null);
    }
  } catch(e) {
    console.log("An error occured while trying to restore session or authenticate user: %o", e)
  }
}

//-----------------------------------------------------------------
// User functions
//-----------------------------------------------------------------
async function read_user_data(session, data) {
  try {
    const objects = await client.readStorageObjects(session, {
      "object_ids" : data
    });
    //console.info("Read objects: %o", objects);
    return objects;
  } catch(e) {
    console.log("An error occured: %o", e);
    return null;
  }
}

async function delete_user_data(session, data) {
  try {
    client.deleteStorageObjects(session, {
      "object_ids" : data
    });
    console.log("Delete objects success");
  } catch(e) {
    console.log("An error occured: %o", e);
  }
}

async function write_user_data(session, data) {
  try {
    client.writeStorageObjects(session, data);
    console.log("Write objects success");
  } catch(e) {
    console.log("An error occured: %o", e);
  }
}

async function list_data(session, collection, user_id) {
  try {
    var objects = await client.listStorageObjects(session, collection, user_id, 100);
    console.log("list objects success");
    var cursor = objects.cursor; 
    while (cursor) {
      console.log("fetching cursor " + cursor);
      let next_objects = await client.listStorageObjects(session, collection, user_id, 100, cursor);
      cursor = next_objects.cursor; 
      let new_array = objects.objects.concat(next_objects.objects)
      objects.objects = new_array; 
    } 
    objects.cursor = null;
    console.log(objects)
    return objects;
  } catch(e) {
    console.log("An error occured: %o", e);
    return null;
  }
}

async function get_account(session) {
  const account = await client.getAccount(session);
  const user = account.user;
  console.info("User id '%o' and username '%o'.", user.id, user.username);
  console.info("User's wallet:", account.wallet);
  console.log(account)
  return account
}

function arrayRemove(arr, value) { 
  return arr.filter(function(ele){ 
     return ele != value; 
  });
}

