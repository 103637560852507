import { useState, useEffect, useContext } from "react"
import { authContext } from '../useAuth';

import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'



export default function RestaurantRating(props) {
  const auth = useContext(authContext);

  const [btn1_checked, setBtn1] = useState('');
  const [btn2_checked, setBtn2] = useState('');
  const [btn3_checked, setBtn3] = useState('');
  const [btn_style, setBtnStyle] = useState('btn');
  const [btn_label, setBtnLabel] = useState('no rating');

  useEffect(() => {
    if (props.rating === 'out_of_the_way') {
      setBtn1('true');
      setBtnStyle('btn btn-outline-success');
      setBtnLabel('Out of My Way');
    } else if (props.rating === 'on_my_way') {
      setBtn2('true');
      setBtnStyle('btn btn-outline-warning');
      setBtnLabel('On My Way');
    } else if (props.rating === 'no_way') {
      setBtn3('true');
      setBtnStyle('btn btn-outline-danger');
      setBtnLabel('No Way');
    } else  {
      setBtnStyle('btn');
      setBtnLabel('no rating');
    }
    //console.log(props.rating)
  //}, [auth, props.restaurant_id, props.rating]);
  });

  const handleSubmit= (e) => {
    if (e.target.name === 'btnradio1' && e.target.value === 'on') {
      let rating = 'out_of_the_way';
      setBtn1('true');
      setBtn2('');
      setBtn3('');
      auth.updateRestaurant(props.restaurant_id, { 'rating' : rating });
    }
    if (e.target.name === 'btnradio2' && e.target.value === 'on') {
      let rating = 'on_my_way';
      setBtn1('');
      setBtn2('true');
      setBtn3('');
      auth.updateRestaurant(props.restaurant_id, { 'rating' : rating });
    }
    if (e.target.name === 'btnradio3' && e.target.value === 'on') {
      let rating = 'no_way';
      setBtn1('');
      setBtn2('');
      setBtn3('true');
      auth.updateRestaurant(props.restaurant_id, { 'rating' : rating });
    }
  }

  function ToggleButtonGroupControlled(id) {
    const [value, setValue] = useState([]);

    const handleChange = (e) => {
      console.log(id)
      console.log(e)
      setValue(e);
    }

    return (
      <ToggleButtonGroup type="checkbox" value={value} onChange={handleChange}>
        <ToggleButton id={id + "tbg-btn-1"} value={1}>
          Out of My Way
        </ToggleButton>
        <ToggleButton id={id + "tbg-btn-2"} value={2}>
          On My Way
        </ToggleButton>
        <ToggleButton id={id + "tbg-btn-3"} value={3}>
          No Way
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }



  function rating() {
    if (props.style === 'mini_static') {
      return (
        <div class="btn-group" aria-label="button group">
          <label class={btn_style}>
          <input type="radio" class="btn-check" autoComplete="off" disabled='disabled'/>
               <i class="fa fa-car"></i>
               {" "}{btn_label}
          </label>
        </div>
      )
    
    } else if (props.style === 'test') {
      return (
        <div>{ToggleButtonGroupControlled(props.restaurant_id, props.rating)}</div>
      )

    } else if (props.style === 'mini') {
      return (
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio1" id={props.restaurant_id + "_btnradio1"} autoComplete="off" checked={btn1_checked}/>
          <label class="btn btn-outline-success" for="btnradio1">
               <i class="fa fa-car"></i>
          </label>

          <input type="radio" class="btn-check" name="btnradio2" id={props.restaurant_id + "_btnradio2"} autoComplete="off" checked={btn2_checked}/>
          <label class="btn btn-outline-warning" for="btnradio2">
               <i class="fa fa-car"></i>
          </label>

          <input type="radio" class="btn-check" name="btnradio3" id={props.restaurant_id + "_btnradio3"} autoComplete="off" checked={btn3_checked}/>
          <label class="btn btn-outline-danger" for="btnradio3">
               <i class="fa fa-car"></i>
          </label>
        </div>
      )
    
    } else {

      return (
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio1" id={props.restaurant_id + "_btnradio1"} autoComplete="off" checked={btn1_checked} onChange={e => handleSubmit(e)} />
          <label class="btn btn-outline-success" for={props.restaurant_id + "_btnradio1"}>
            <i class="fa fa-car"></i>
            {" "}Out of My Way
          </label>

          <input type="radio" class="btn-check" name="btnradio2" id={props.restaurant_id + "_btnradio2"} autoComplete="off" checked={btn2_checked} onChange={e => handleSubmit(e)}/>
          <label class="btn btn-outline-warning" for={props.restaurant_id + "_btnradio2"}>
            <i class="fa fa-car"></i>
            {" "}On My Way
          </label>

          <input type="radio" class="btn-check" name="btnradio3" id={props.restaurant_id + "_btnradio3"} autoComplete="off" checked={btn3_checked} onChange={e => handleSubmit(e)}/>
          <label class="btn btn-outline-danger" for={props.restaurant_id + "_btnradio3"}>
            <i class="fa fa-car"></i>
            {" "}No Way
          </label>
        </div>
      )
    }
  }

  return (
    <div>
      {rating()}
    </div>

  )
}


