import { useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { authContext } from '../useAuth';

export default function Camera(props) {
  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const handleCapture = (target) => {
    let restaurant_id;
    let server_ip = "https://yumsights.com:8888";

    console.log(target);

    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        var form = new FormData();

        console.log(file);
        form.append('image', file);
        console.log(form);

        const requestOptions = {
          method: 'POST',
          body: form
        };

        if (props.restaurant_id) {
          restaurant_id = props.restaurant_id;
        } else {
          restaurant_id = 0;
        }

        fetch(server_ip + '/capture', requestOptions)
          .then(response => response.json())
          .then(response => {
            console.log(response);
            var data = response.array[0];
            auth.addPhoto(restaurant_id, {'filename':data.filename,'url':data.url,'date':data.date,'orientation':'rotate0','description':''});
          })
          .then(navigate('/restaurant/' + props.restaurant_id));
      }
    }
  };

  function capture_mode() {
    if (props.restaurant_id) {
      return(
       <div class="row">

       <div class="col">
         <label><i class="fa fa-camera fa-lg">
         {" "}Take Photo
         <input 
           accept="image/*" 
           id="icon" 
           type="file" 
           hidden
           capture="environment"
           onChange={(e) => handleCapture(e.target)}
         />
         </i>
         </label>
       </div>

       <div class="col">
         <label><i class="fa fa-upload fa-lg">
         {" "}Upload Photo
         <input 
           accept="image/*" 
           id="icon" 
           type="file" 
           hidden
           onChange={(e) => handleCapture(e.target)}
         />
         </i>
         </label>
       </div>

       </div>
      )
    }
  }

  return (
    <div>
    {capture_mode()}
    </div>
  )
}

