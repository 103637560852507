import React from 'react';
import { Link } from "react-router-dom"

export default function Leaderboard(props) {

  function render_record(dataItem, k) {
    var textstyle = 'text-success';

    if (dataItem.rating == "no_rating") {
       textstyle = 'text-secondary';
    } else if (dataItem.rating == "out_of_the_way") {
       textstyle = 'text-success';
    } else if (dataItem.rating == "on_my_way") {
       textstyle = 'text-warning';
    } else if (dataItem.rating == "no_way") {
       textstyle = 'text-danger';
    }

    return (
           <tr onClick={()=> props.callback(dataItem.restaurant_id)}>
             <th scope="row">{k + 1}</th>
             <td>{dataItem.name}</td>
             <td>{dataItem.name}</td>
             <td>9999</td>
             <td class={textstyle}><i class="fa fa-car"></i></td>
           </tr>
    );
  }

  return (
   <div>
   <table class="table">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Restaurant</th>
        <th scope="col">Dish</th>
        <th scope="col">Score</th>
        <th scope="col">My Rating</th>
      </tr>
      <tbody>
         {props.data.map((dataItem,k) => (
           render_record(dataItem,k)
         ))}
     </tbody>
   </table>
   </div>
  )
}


