import React, { Component } from 'react';
import { GoogleMap, LoadScript, InfoBox, Marker } from '@react-google-maps/api';
import { authContext } from '../useAuth';

const containerStyle = {
  width:  'auto',
  height: '300px'
};

export default class LeaderboardMap extends Component {
  static contextType = authContext;

  constructor (props) {
    super(props)

    this.state = {
       data: [],
       center : JSON.parse(this.props.location),
       pos : { lat: 37.329, lng: -121.9482722},
       pos2 : { lat: 37.289, lng: -121.9982722},

       zoom : 12
    }
  }

  componentDidMount() {
    console.log(this.props.location);
  }

  render() {
    return (
      <div>
      <LoadScript googleMapsApiKey="AIzaSyCJTzxUHcFbparhXNCcjASzYnwG6fk6kGA">

        <GoogleMap
          id="placemap"
          mapContainerStyle={containerStyle}
          center={this.state.center}
          zoom={this.state.zoom}
        >

            <Marker key='current'
              label="1"
              position={JSON.parse(this.props.location)}
            >
            </Marker>

            <Marker key='2nd'
              label="2"
              position={this.state.pos}
            >
            </Marker>

            <Marker key='3rd'
              label="3"
              position={this.state.pos2}
            >
            </Marker>

        </GoogleMap>

      </LoadScript>
      </div>
    )
  }
}
