import React from 'react';
import { useState, useEffect, useContext } from "react"
import { Link } from 'react-router-dom';
import { authContext } from '../useAuth';

import DishRating from './DishRating';

export default function DishCard(props) {
  const data = props.data  
  const auth = useContext(authContext);

  const [ratingForm, setRatingForm] = useState("mini_static");

  const [notes, setNotes] = useState('');
  const [notechanged, setNoteChanged] = useState(false);

  function toggleRatingForm() {
     if (ratingForm === "mini") {
       setRatingForm("mini_static")
     } else {
       setRatingForm("mini")
     }
  }

  function handleDelete() {
    if (window.confirm('Remove dish: ' + data.name)) {
      auth.delSigDish(props.restaurant_id, data.id)
    }
  }

  function handleNotes(e) {
    setNotes(e.target.value);
    setNoteChanged(true);
  }

  function handleNoteInit() {
    setNoteChanged(false);
  }

  function handleNoteDone(sigdish_id) {
    if (notechanged === true) {
      if (window.confirm('Save changes?')) {
        console.log(notes);
        var querydata = {
          "description" : notes
        }
        console.log('sigdish_id=' + sigdish_id);
        auth.updateSigDish(sigdish_id, querydata);
      } else {
      }
    }
  }
 
  return (
    <div>
    <div class="col">
      <h4>{data.name} ({data.dish})</h4>
         <textarea class="form-control" rows="2" placeholder="Enter description" onChange={(e) => handleNotes(e)} onFocus={() => handleNoteInit()} onBlur={() => handleNoteDone(data.id)} defaultValue={data.description}>
         </textarea>
    </div>
    <div class="col-auto">
       <DishRating restaurant_id={props.restaurant_id} dish_id={data.id} rating={data.rating} style={ratingForm}/>
    </div>

    <div class="row">
    <div class="col-10"></div>
    <div class="col-auto">
    <button type="submit" class="btn" onClick={() => toggleRatingForm()}>
             <i class="fa fa-pencil"></i>
    </button>
    <button type="submit" class="btn" onClick={() => handleDelete()}>
             <i class="fa fa-trash"></i>
    </button>
    </div>
    </div>

    </div>
  )
}


