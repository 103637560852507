import React from 'react';
import { useState, useEffect, useContext } from "react"
import { Link } from 'react-router-dom';
import { authContext } from '../useAuth';

import Checkbox from './Checkbox';
import Stats from './Stats';
import RestaurantSidebar from './RestaurantSidebar';
import RestaurantCard from './RestaurantCard';


export default function RestaurantMain(props) {
  const auth = useContext(authContext);

  const [refresh, setRefresh] = useState(0);
  const [view, setView] = useState('grid');
  const [activeGridBtn, setActiveGridBtn] = useState('active-view-btn');
  const [activeListBtn, setActiveListBtn] = useState('');

  const [dataList, setDataList] = useState([]);
  const [recentList, setRecentList] = useState([]);

  const [checkedItems, setCheckedItems] = useState({});
  const [nameFilter, setNameFilter] = useState('');

  const handleChange = (event) => {
    setCheckedItems({...checkedItems, [event.target.name] : event.target.checked });
    console.log("checkedItems: ", checkedItems);
  }

  const checkboxes = [
      {
          name: 'no_rating',
          key: 'checkBox0',
          label: 'No Rating'
      },
      {
          name: 'out_of_the_way',
          key: 'checkBox1',
          label: 'Out of My Way'
      },
      {
          name: 'on_my_way',
          key: 'checkBox2',
          label: 'On My Way'
      },
      {
          name: 'no_way',
          key: 'checkBox3',
          label: 'No Way'
      }
  ];

  function handleSearch(e) {
    e.preventDefault();
    console.log(e.target.value)
    setNameFilter(e.target.value.toLowerCase())
  }

  useEffect(() => {
    auth.listRestaurant().then(data => {
      console.log(data);
      data.sort((a,b) => a.name.localeCompare(b.name));
      setDataList(data);
    });

    auth.recentRestaurant().then(data => {
      setRecentList(data);
    });
  }, [auth, refresh]);

  useEffect(() => {
    setCheckedItems({'no_rating':true, 'out_of_the_way':true, 'on_my_way':true, 'no_way':true} );
  }, []);

  function ViewControl() {
    if (view === 'grid') {
      return (
        <div>
           <a class="change-view-btn" onClick={() => setView('list')}><i class="fa fa-th-list"></i></a>
           <a class="change-view-btn active-view-btn" onClick={() => setView('grid')}><i class="fa fa-th-large"></i></a>
           <span class="title">{" "}Grid view</span>
        </div>
      )
    } else {
      return (
        <div>
           <a class="change-view-btn active-view-btn" onClick={() => setView('list')}><i class="fa fa-th-list"></i></a>
           <a class="change-view-btn" onClick={() => setView('grid')}><i class="fa fa-th-large"></i></a>
           <span class="title">{" "}List view</span>
        </div>
      )
    }
  }

  function toggleRefresh() {
    if (refresh == 0) {
      setRefresh(1)
    } else {
      setRefresh(0)
    }
  }

  function customFilter(data) {
    if (checkedItems[data.rating] === true) {
      let name = data.info.name.toLowerCase() 
      let tags = data.tags.toLowerCase() 
      if (name.includes(nameFilter) || tags.includes(nameFilter)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (

<div>

<div class="properties-section content-area">
    <div class="container">
        <Stats />
        <div class="row">
           <div class="col-lg-4 col-md-12 col-xs-12">
                <div class="sidebar-widget advanced-search">
                    Selected entries: {dataList.filter(customFilter).length} out of {dataList.length}
                    <div class="main-title-2">
                        <h1>Advanced Search</h1>
                    </div>

                    <h4>Search by Name</h4>
                       <div class="form">
                          <input type="text" name="your-name" class="form-control" placeholder="Search.." onChange={(e) => handleSearch(e)}/>
                       </div>
                    <br />

                    <h4>Restaurant Rating</h4>
                    {checkboxes.map(item => (
                         <li>
                         <label key={item.key}>
                             <Checkbox name={item.name} checked={checkedItems[item.name]} onChange={handleChange} />
                             {item.name}
                         </label>
                         </li>
                    ))}
                    <br />

                    <div class="d-none d-md-block"> 
                    <h4>Recent</h4>
                    <div class="row" id="quicklist">
                      {recentList
                        .filter(customFilter)
                        .map(dataItem => (
                        <li>
                          <Link to={"/restaurant/" + dataItem.restaurant_id} class="sign-in">{dataItem.name}</Link>
                        </li>
                      ))}
                    </div>
                    <br />
                    <h4>Quick Link</h4>
                    <div class="row" id="quicklist">
                      {dataList
                        .filter(customFilter)
                        .map(dataItem => (
                        <li>
                          <Link to={"/restaurant/" + dataItem.restaurant_id} class="sign-in">{dataItem.name}</Link>
                        </li>
                      ))}
                    </div>
                    </div>
                </div>
           </div>

            <div class="col-lg-8 col-md-12 col-xs-12">
                <div class="option-bar">
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-6">
                            <h4>
                                {ViewControl()}
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>

                <div class="row">
                  {dataList
                    .filter(customFilter)
                    .map(dataItem => (
                    <RestaurantCard type={view} data={dataItem}/>
                  ))}
                </div>

                <div class="pagination-box text-center">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#">Prev</a>
                            </li>
                            <li class="page-item"><a class="page-link  active" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

</div>
)

}
