import React from 'react';
import { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { authContext } from '../useAuth';

export default function Gallery(props) {
  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const [dataList, setDataList] = useState([{}]);

  useEffect(() => {
    auth.listPhoto().then(results => {
      console.log("LISTPHOTOS results");
      console.log(results);
      if (results) {
        setDataList(results);
      }
    });
  }, [auth]);

  function render_photo(data) {
    console.log("RENDER PHOTO");
    //console.log(data);
    var restaurant_url = '/restaurant/' + data.restaurant_id;
    if (data) {
      return (
        <div>
          <p>{data.restaurant_name}</p>
          <Link to={restaurant_url} className="App-link">
            <img src={data.url} alt="TBD" width="150" className={data.orientation}/>
          </Link>
          <br />
          <br />
        </div>
      );
    }
  }

  return (

<div class="properties-gallery content-area">
    <div class="container">
        <div class="main-title">
            <h1>Photos Gallery</h1>
        </div>

        <ul class="list-inline-listing filters filters-listing-navigation">
            <li class="active btn filtr-button filtr" data-filter="all">All</li>
            <li data-filter="1" class="btn btn-inline filtr-button filtr">Out of My Way</li>
            <li data-filter="2" class="btn btn-inline filtr-button filtr">On My Way</li>
            <li data-filter="3" class="btn btn-inline filtr-button filtr">No Way</li>
        </ul>

           <div className="row">
                <div className="col-md">
                    <div className="row no-gutters">
                    {dataList.map((val, k) => {
                        return (
                        <div className="col" key={k}>
                            {render_photo(val)}
                        </div>)
                        })
                    }
                    </div>
                </div>
           </div>

    </div>
</div>

  )
}

