import { useState, useEffect, useContext } from "react"
import { authContext } from '../useAuth';


export default function DishRating(props) {
  const auth = useContext(authContext);

  const [btn1_checked, setBtn1] = useState('');
  const [btn2_checked, setBtn2] = useState('');
  const [btn3_checked, setBtn3] = useState('');
  const [btn_style, setBtnStyle] = useState('btn');
  const [btn_label, setBtnLabel] = useState('no rating');

  useEffect(() => {
    if (props.rating === 'why_i_come_here') {
      setBtn1('true');
      setBtnStyle('btn btn-outline-success');
      setBtnLabel('Why I Come Here');
    }
    if (props.rating === 'order_again') {
      setBtn2('true');
      setBtnStyle('btn btn-outline-warning');
      setBtnLabel("I'd order again");
    }
    if (props.rating === 'meh') {
      setBtn3('true');
      setBtnStyle('btn btn-outline-danger');
      setBtnLabel('Meh');
    }
    console.log(props.rating)
  }, [auth, props.rating, props.dish_id, props.restaurant_id]);

  const handleSubmit= (e) => {
    console.log(e);
    console.log(props.dish_id);
    console.log(e.target.name);

    if (e.target.name === 'btnradio1' && e.target.value === 'on') {
      let rating = 'why_i_come_here';
      setBtn1('true');
      setBtn2('');
      setBtn3('');
      auth.updateSigDish(props.dish_id, { 'rating' : rating });
    }
    if (e.target.name === 'btnradio2' && e.target.value === 'on') {
      let rating = 'order_again';
      setBtn1('');
      setBtn2('true');
      setBtn3('');
      auth.updateSigDish(props.dish_id, { 'rating' : rating });
    }
    if (e.target.name === 'btnradio3' && e.target.value === 'on') {
      let rating = 'meh';
      setBtn1('');
      setBtn2('');
      setBtn3('true');
      auth.updateSigDish(props.dish_id, { 'rating' : rating });
    }
  }

  function rating() {
    if (props.style === 'mini_static') {
      return (
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio" id={props.dish_id + "_btnradio"} autocomplete="off" disabled='disabled'/>
          <label class={btn_style} for={props.dish_id + "_btnradio"}>
               <i class="fa fa-cutlery"></i>
               {" "}{btn_label}
          </label>
        </div>
      )
    
    } else {

      return (
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio1" id={props.dish_id + "_btnradio1"} autocomplete="off" checked={btn1_checked} onChange={e => handleSubmit(e)} />
          <label class="btn btn-outline-success" for={props.dish_id + "_btnradio1"}>
            <i class="fa fa-cutlery"></i>
            {" "}Why I Come Here
          </label>

          <input type="radio" class="btn-check" name="btnradio2" id={props.dish_id + "_btnradio2"} autocomplete="off" checked={btn2_checked} onChange={e => handleSubmit(e)}/>
          <label class="btn btn-outline-warning" for={props.dish_id + "_btnradio2"}>
            <i class="fa fa-cutlery"></i>
            {" "}Order Again
          </label>

          <input type="radio" class="btn-check" name="btnradio3" id={props.dish_id + "_btnradio3"} autocomplete="off" checked={btn3_checked} onChange={e => handleSubmit(e)}/>
          <label class="btn btn-outline-danger" for={props.dish_id + "_btnradio3"}>
            <i class="fa fa-cutlery"></i>
            {" "}Meh
          </label>
        </div>
      )
    }
  }

  return (
    <div>
      {rating()}
    </div>

  )
}


