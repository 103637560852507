import { useState, useEffect, useRef, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { authContext } from '../useAuth';

import Alert from 'react-bootstrap/Alert';

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};


export default function AddRestaurant(props) {
  const auth = useContext(authContext);
  const autoCompleteRef = useRef(null);

  const [query, setQuery] = useState('');

  const [cuisineList, setCuisineList] = useState([]);
  const [cuisine, setCuisine] = useState('');

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [location, setLocation] = useState('');
  const [placeId, setPlaceId] = useState('');
  const [notes, setNotes] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCJTzxUHcFbparhXNCcjASzYnwG6fk6kGA&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );

    auth.listCuisine().then(results => {
      //console.log("Cuisine results");
      //console.log(results);
      setCuisineList(results);
      setCuisine(results[0].name);
    });
  }, []);

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["establishment"], componentRestrictions: { } }
    );
    autoComplete.setFields(["formatted_address", "place_id", "name", "geometry"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log(addressObject);

    setName(addressObject.name);  
    setAddress(addressObject.formatted_address);  
    setLocation(addressObject.geometry.location);  
    setPlaceId(addressObject.place_id);  
    setNotes('');  
  }

  function handleChangeName(e) { 
    setName(e.target.value);  
  }

  function handleChangeAddress(e) { 
    setAddress(e.target.value);  
  }

  function handleChangeNotes(e) { 
    setNotes(e.target.value);  
  }

  function handleChangeCuisine(e) {
    setCuisine(e.target.value);
  }

  function handleSubmit(e) { 
    e.preventDefault();

    var querydata = {
      "name" :    name,
      "address" : address,
      "location" : JSON.stringify(location),
      "place_id" : placeId,
      "description" : notes, 
      "cuisine" : cuisine 
    }
    console.log(querydata);

    auth.addRestaurant(querydata).then(data => {
      console.log('New restaurant_id = ' + data);
      setStatus('Added restaurant \'' + name + '\' to your collection');  
    });

    setQuery('');
    setName('');  
    setAddress('');
    setLocation('');
    setPlaceId('');
    setNotes('');  
  }

  function status_bar() { 
    if (status === '') {
      return;
    }

    return (
      <div class="row">
         <div class="col-lg-12">
           <br />
           <Alert variant='success'>
             {status}
           </Alert>
           <Alert variant='warning'>
             Warning:  Photo upload functionality not yet implemented on this page
           </Alert>
         </div>
      </div>
    )
  }

  return (

<div class="content-area-7 submit-property advanced-search">
    <div class="container">
        <div class="row">

            <div class="col-md-12">
                <div class="notification-box">
                    <h3>Add a restaurant to your collection</h3>
                    <p>Search:</p>

                     <input
                        ref={autoCompleteRef}
                        onChange={event => setQuery(event.target.value)}
                        placeholder="Enter a restaurant name"
                        value={query}
                      />
                </div>
            </div>

            <div class="col-md-12">
                <div class="submit-address">
                    <form onSubmit={e => {handleSubmit(e)}}>
                        <div class="main-title-2">
                            <h1>Restaurant Information</h1>
                        </div>

                        <select onChange={handleChangeCuisine}>
                          {cuisineList.map((option) => (
                             <option selected={option.name === cuisine} value={option.name}>{option.name}</option>
                          ))}
                        </select>
                        <br />
                        <br />

                        <div class="row mb-30 ">
                            <div class="row">
                                <div class="col-lg-4  col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label for="inputproperty-title1" class="form-label">Name</label>
                                        <input type="text" name="property-title" class="form-control" id="inputproperty-title1" value={name} onChange={e => handleChangeName(e)} placeholder="Restaurant Name" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="main-title-2">
                            <h1>Location</h1>
                        </div>
                        <div class="row mb-30 ">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label for="inputaddress1" class="form-label">Address</label>
                                    <input type="text" name="address" class="form-control" id="inputaddress1" value={address} onChange={e => handleChangeAddress(e)} placeholder="Address" />
                                </div>
                            </div>
                        </div>

                        <div class="main-title-2">
                            <h1>Detailed Information</h1>
                        </div>
                        <div class="row mb-30">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Notes</label>
                                    <textarea name="notes" class="form-control" rows="3" value={notes} onChange={e => handleChangeNotes(e)} placeholder="Enter your personal notes here"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="main-title-2">
                            <h1>Photos</h1>
                        </div>
                        <div id="myDropZone" class="dropzone dropzone-design mb-50">
                            <div class="dz-default dz-message"><span>Drop files here to upload</span></div>
                        </div>


                        <div class="row">
                            <div class="col-lg-12">
                                <button type="submit" class="btn-2 btn-defaults">Submit<i class="arrow"></i></button>
                            </div>
                        </div>

                        {status_bar()}
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

