import { useContext } from "react"
import { Link } from "react-router-dom"
import { authContext } from '../useAuth';

import Banner from './Banner';
import BannerProxy from './BannerProxy';
import SearchArea from './SearchArea';
import FeaturedProperties from './FeaturedProperties';
import RecentProperties from './RecentProperties';
import Services from './Services';
import Counters from './Counters';
import Popular from './Popular';
import Agent from './Agent';
import Testimonial from './Testimonial';
import Blog from './Blog';
import Intro from './Intro';
import PropertyVideo from './PropertyVideo';

export default function About(props) {
  const auth = useContext(authContext);

  if (auth.authed) { 
    return (
       <div>
          <Banner />
          <SearchArea />
          <FeaturedProperties />
          <RecentProperties />
          <Counters />
          <Services />
          <Popular />
       </div>
    )

  } else {
    return (
       <div>
          <BannerProxy />
       </div>
    )
  }
}

