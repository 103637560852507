import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './useAuth';

//import './bootstrap/dist/css/bootstrap.min.css';
//import './css/bootstrap.min.css';
//import './css/bootstrap-submenu.css';
//import './css/bootstrap-select.min.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import RequireAuth from "./RequireAuth";

import Login from './components/Login';
import Signup from './components/Signup';
import Forgot from './components/Forgot';
import Logout from './components/Logout';

import Home from './Home';
import Admin from './components/Admin';
import About from './components/About';
import RestaurantMain from './components/RestaurantMain';
import RestaurantDetail from './components/RestaurantDetail';
import AddRestaurant from './components/AddRestaurant';
import Map from './components/Map';
import Gallery from './components/Gallery';
import User from './components/User';
import Quest from './components/Quest';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />

          <Route path="admin" element={
            <RequireAuth > <Admin /> </RequireAuth>
          }/>

          <Route path="about" element={
            <RequireAuth > <About /> </RequireAuth>
          }/>

          <Route path="restaurant" element={
            <RequireAuth > <RestaurantMain /> </RequireAuth>
          }/>

          <Route path="restaurant/:restaurant_id" element={
            <RequireAuth > <RestaurantDetail /> </RequireAuth>
          }/>

          <Route path="addrestaurant" element={
            <RequireAuth > <AddRestaurant /> </RequireAuth>
          }/>

          <Route path="map" element={
            <RequireAuth > <Map /> </RequireAuth>
          }/>

          <Route path="gallery" element={
            <RequireAuth > <Gallery /> </RequireAuth>
          }/>

          <Route path="user" element={
            <RequireAuth > <User /> </RequireAuth>
          }/>

          <Route path="quest" element={
            <RequireAuth > <Quest /> </RequireAuth>
          }/>
        <Route path="login"  element={<Login />} />

        </Route>

        <Route path="login"  element={<div><AuthProvider><Login /></AuthProvider></div>} />
        <Route path="signup" element={<div><AuthProvider><Signup /></AuthProvider></div>} />
        <Route path="forgot" element={<div><AuthProvider><Forgot /></AuthProvider></div>} />
        <Route path="logout" element={<div><AuthProvider><Logout /></AuthProvider></div>} />

      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
